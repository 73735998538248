.chip {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
  font-weight: bold;
  align-items: center;
  margin-bottom: 2.5rem;
}
.arrow {
  color: #555555;
  font-size: 2rem;
  font-weight: bold;
  margin: 0rem 0.7rem;
}
