.label {
  width: 10rem;
  //height: 2rem;
  font-size: 1.2rem;
}

.labelContainer {
  min-width: 15rem;
  justify-content: center;
  display: flex;
}

.details {
  display: flex;
  align-items: center;
}

.detailsContainer {
  padding: 0 1rem;
}

.detailsInner {
  padding: 2rem 0;
  background-color: #F7F9FC;
  border: solid 0.1rem #EFF2F7;
}

.title {
  font-weight: bold;
  color: #093791;
  font-size: 1.8rem;
}
