.rootContainer {
  border: 0.1rem solid #D7E2F4;
  background-color: #FFFFFF;
  padding: 0 8rem;
}

.row {
  padding: 3rem 3rem;
  display: flex;
  align-items: center;
}

.rowTextArea {
  align-items: flex-start;
}

.subtitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 24.8rem;
}

.divider {
  margin: 0;
  height: 0.1rem;
  background: #EEEEEE;
}

.nickname {
  color: #093791;
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-right: 1rem;
}

.blackText {
  font-size: 1.6rem;
  line-height: 3.2rem;
}

.inputContainer {
  flex: 1;
}

.required {
  border-radius: 1rem;
  border: 0.1rem solid #EA397D;
  color: #EA397D;
  margin-right: 3rem;
  font-size: 1rem;
  font-weight: normal;
  padding: 0.2rem 0.6rem;
  position: relative;
  background: #FFFFFF;
  line-height: normal;
}

.submitContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
}
