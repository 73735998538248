.main {
  padding: 4.5rem;

  .image {
    text-align: center;
  }

  .title {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 2.4rem;
    line-height: 3.5rem;
    letter-spacing: 0.1em;
    margin-top: 2rem;

    color: #093791;

  }

  .mainContent {
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.8rem;
    text-align: center;
    color: #222222;
    margin-top: 1rem;
  }

}

.form {
  margin: 0 auto;
  background: #FFFFFF;
  max-width: 104rem;
  width: 100%;
  height: 100%;
}

.formContent {
  padding: 5rem 8rem 6rem 8rem;
}

@media screen and (max-width: 600px) {
  .formContent {
    padding: 2rem;
  }
  .main {
    padding: 0;
  }
}
