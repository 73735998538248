.starRating {
  position: relative;
  width: 8.3rem;
  height: 1em;
  font-size: 25px;
  line-height: 2rem;
}
.starRatingFront {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
