.rootContainer {
  border: 0.1rem solid #d7e2f4;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 6rem 1.6rem;
  max-width: 104rem;
  margin-left: auto;
  margin-right: auto;
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #00a8f0;
  font-size: 2.8rem;
}

.message {
  font-size: 1.4rem;
  margin-top: 1.6rem;
}

.messageCentering {
  text-align: center;
}

.link {
  color: #00a8f0;
  font-weight: bold;
}

.projectImg {
  width: 70%;
  border-radius: 1rem;
  border-width: 0.1rem;
  border-style: solid;
  border-color: #ffd6ff;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.roundedButton {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .projectImg {
    width: 100%;
    border-radius: 1rem;
    border-width: 0.1rem;
    border-style: solid;
    border-color: #ffd6ff;
  }
}
