.container {
  padding: 5rem;
  background: #FFFFFF;
  border: 0.1rem solid #D7E2F4;
  box-sizing: border-box;
  margin-top: 3rem;
}

.row {
  padding: 3rem;
  border-bottom: 0.1rem solid #D7E2F4;
}

.rowItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subTitle {
  margin: auto 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: #093791;
  width: 17rem;
}

.required {
  padding: 0.4rem 0.7rem;
  margin-right: 3rem;
  height: 2.2rem;
  width: 4.4rem;
  border-radius: 2rem;
  border: 0.1rem solid #EA397D;
  color: #EA397D;
  font-size: 1.4rem;
}

.formArea {
  width: 59.4rem;
  display: flex;
  justify-content: space-between;
}

.lastName {
  margin-right: 2rem;
  width: 50%
}

.firstName {
  width: 50%
}

.form {
  text-align: right;
  width: 100%;

  input {
    height: 3rem;
    width: 55.6rem;
    font-size: 1.6rem;
    padding: 1rem 2rem;
  }
}

.addressFlex {
  display: flex;
  align-items: center;
}

.notice {
  margin-left: 43.2rem;
  margin-top: 0.7rem;
  font-size: 1.2rem;
  color: #999999;
  width: 100%;
}

.smallWidth {
  width: 40%
}

.mediumWidth {
  width: 52%
}

.largeWidth {
  width: 75%;
}

.inputsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%
}

.telsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%
}

.titleName {
  width: 10rem;
  margin-right: 5rem;
}

.rowItemMarginTop {
  margin-top: 2rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
}

.error {
  font-size: 1.6rem;
  color: #EA397D
}

.hyphen {
  margin: 1rem
}
