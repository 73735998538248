.container {
  display: flex;

  .content {
    flex: 1;
    background-color: #f7f9fc;

    .main {
      max-width: 104rem;
      margin: 0 auto;

      article {
        background-color: #ffffff;
        border: 0.1rem solid #d7e2f4;
        max-width: 88rem;
        margin: 3rem auto 0;
        padding: 6rem 8rem;

        h2 {
          font-size: 3.4rem;
          font-weight: bold;
          color: #093791;
        }

        .text {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.formItem {
  border-bottom: 0.1rem solid #ddd;
  padding: 3.5rem 2rem 3.5rem 3rem;

  .formControlLabel {
    font-size: 1.2rem;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;

  .submitButton {
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 5rem;
    text-align: center;
    width: 40rem;
    height: 7rem;
    border-radius: 0.8rem;
  }
}
