.inner {
  margin: 0 auto;

  .text {
    padding: 4.5rem 0 4.2rem;
    text-align: center;
    font-size: 1.75rem;
  }

  .links {
    margin: 0 auto;
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.normalWidth {
  max-width: 104rem;
}

.otherWidth {
  max-width: 40%;
}

.textNormalColor {
  color: #6c8ac4;
}

.textWhiteColor {
  color: #d7e2f4;
}
@media screen and (max-width: 600px) {
  .inner {
    width: 20rem !important;
  }
  .otherWidth {
    max-width: 20rem !important;
  }
  .links {
    margin: 0 auto;
    padding-top: 2rem;
    display: block !important;
    justify-content: space-between;
    text-align: center !important;
  }
  .text {
    padding-top: 2rem !important;
    padding-bottom: 1rem !important;
    text-align: center;
  }
}
