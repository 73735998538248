.container {
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .container {
    padding-top: 0rem;
  }
}
