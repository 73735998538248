@mixin contained {
  color: #fff;
  padding: 0.1rem 1rem;
  border-radius: 1.4rem;
  text-align: center;
  flex-grow: 1;
  font-weight: bold;
  margin: 0 0.3rem;
  @media screen and (max-width: 600px) {
    margin: 0rem;
  }
  max-width: 8rem;
}

@mixin outlined {
  color: #fff;
  padding: 0.1rem 1rem;
  border-radius: 1.4rem;
  text-align: center;
  flex-grow: 1;
  font-weight: bold;
  margin: 0 0.3rem;
  max-width: 8rem;
}

.draft {
  @include outlined;
  color: #093791;
  border: 0.05rem solid #093791;
  margin: 0 0.3rem;
}

.nonactive {
  @include outlined;
  color: #999999;
  border: 0.05rem solid #dddddd;
}

.hiring {
  @include contained;
  background: #f48400;
  border: 0.05rem solid #f48400;
}

.progress {
  @include contained;
  background: #093791;
  border: 0.05rem solid#093791;
}

.evaluation {
  @include contained;
  background: #77c000;
  border: 0.05rem solid #77c000;
}

.request {
  @include contained;
  background: #743eb9;
  border: 0.05rem solid #743eb9;
}

.confirmed {
  @include contained;
  background: #ea397d;
  border: 0.05rem solid #ea397d;
}

.finished {
  @include contained;
  background: #6c8ac4;
  border: 0.05rem solid #6c8ac4;
}

.withdraw {
  color: #007eb4;
  padding: 0.1rem 0rem;
  border-radius: 1.4rem;
  text-align: center;
  flex-grow: 1;
  font-weight: bold;
  margin: 0 0.5rem;
  border: 0.05rem solid #007eb4;
}

.judge {
  @include outlined;
  color: #ea397d;
  border: 0.05rem solid #ea397d;
}
.paymentFailed {
  @include contained;
  background: #d10040;
  border: 0.05rem solid #d10040;
}

.arrow {
  color: #555555;
  font-size: 5rem;
  font-weight: bold;
  margin: 0rem 0.7rem;
}

.done {
  @include contained;
  background: #999;
}
