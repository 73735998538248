.parent {
  position: relative;
  margin: 0 auto;
}
.notificationBatch {
  position: absolute;
  height: 1.7rem;
  width: 1.7rem;
  border-radius: 1rem;
  background-color: #ff307f;
}

.containerItem {
  margin: 0 auto;
}
