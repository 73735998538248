.error {
  font-size: 1.6rem;
  color: #f44336;
}

.errorContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
