.titleContainer {
  display: flex;
}

.title {
  margin-left: 1rem;
  color: #D10040;
  font-weight: bold;
}

.noticeContainer {
  margin-top: 1rem;
  border: 0.2rem solid #D10040;
  background: #FFFEEE;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.notice {
  color: #D10040;
  font-weight: bold;
  font-size: 1.2rem;
}

.linkButton {
  display: flex;
  margin: 0.5rem 0;
}
