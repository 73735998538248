.tags {
  border-radius: 2rem;
  color: #ffffff;
  margin: 0.5rem;
  font-size: 1.4rem;
  padding: 0.8rem 3.5rem;
  position: relative;
  background: #093791;
  font-style: normal;
  font-weight: normal;
  text-align: center;
}
