.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 33, 99, 0.9);

  .container {
    position: absolute;
    right: 0;
    left: 0;
    background-color: #fff;
    width: 70rem;
    margin: auto;
    border-radius: 0.8rem;
    top: 20%;

    .inner {
      max-width: 51rem;
      margin: 5rem auto 6rem;
    }
  }
}

.profile {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-top: 0.1rem solid #d7e2f4;
  border-bottom: 0.1rem solid #d7e2f4;
  padding: 4rem 0;
  margin-top: 3rem;

  .image {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    margin: 0 auto;
  }

  .name {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    font-size: 1.8rem;
    font-weight: bold;
    color: #222;
  }

  .role {
    font-size: 1.2rem;
    color: #999;
  }
}

.header {
  text-align: center;

  .subTitle {
    color: #00a8f0;
    font-size: 2.8rem;
    margin-top: 1.5rem;
  }
}

.button {
  text-align: center;
  margin-top: 5rem;
}

.triangle {
  margin: 1.5rem auto;
  color: #093791;
  font-size: 1.4rem;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.paperPlane {
  text-align: center;
}

.textLarge {
  color: #093791;
  font-size: 2.4rem;
  line-height: 3.5rem;
  letter-spacing: 0.1em;
  text-align: center;
  margin-top: 2rem;
}

.textSmall {
  color: #222;
  font-size: 1.6rem;
  line-height: 3.2rem;
  text-align: center;
  margin-top: 1.5rem;
}

.closeButton {
  margin: 4rem auto 1.5rem;
  text-align: center;
  width: 30rem;
}

.fullWidth {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marginTop {
  margin-top: 4rem;
}
