.tabbar {
  margin-top: 2rem;
}
.mainTitleContainer {
  display: flex;
}
.main {
  padding: 5rem;
  background: #ffffff;
  border: 0.1rem solid #d7e2f4;
  box-sizing: border-box;
}
.information {
  display: flex;
  align-items: center;
  margin: 0 0rem 4rem 2rem;
}
.informationText {
  font-style: normal;
  font-size: 1.7rem;
  line-height: 2.4rem;
  color: #ea397d;
}
.plusButtonImg {
  margin-right: 1rem;
}

.pageNation {
  display: flex;
  justify-content: center;
}

.plusButtonContainer {
  margin: 3rem 0;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 4rem;
  margin-bottom: 4rem;
}

.taskContainer {
  margin-bottom: 5rem;
  max-width: 104rem;
}

.rootContainer {
  width: 100%;
  max-width: 104rem;
  margin: auto;
}

.button {
  width: auto;
}
@media screen and (max-width: 600px) {
  .container {
    margin-bottom: 5rem;
  }
  .plusButtonImg {
    width: 1.5rem;
    margin-right: 0.5rem;
  }
}
