/* base-------------------------------- */
html,
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
  overscroll-behavior: none;
}

body {
  font-size: 1.6rem;
  color: #222222;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: auto;
  //background-color: #F7F9FC;
  background-color: #f7f9fc;
}

dl,
dd,
dt {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='date']::-webkit-clear-button {
  -webkit-appearance: none;
}
input:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
a {
  margin: 0;
  word-wrap: break-word;
}
a {
  color: #262626;
  text-decoration: none;
  display: inline-block;
}
a:hover {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}
i {
  display: inline-block;
}
main {
  width: 100%;
}

:global {
  .wrap {
    border: 0.1rem solid #eff2f7;
    background: #fff;
    border-radius: 0.8rem;
    padding: 2rem;
  }
  .clearfix::after {
    content: '';
    display: block;
    clear: both;
  }
}
