.main {
  max-width: 104rem;
  width: 100%;
  height: 8rem;
  background-image: url("../../../../assets/background_co_signup_text.svg");
  margin: 2rem auto 0 auto;
}

.signUpText {
  text-align: center;
  transform: translateY(50%);
  font-style: normal;
  font-weight: bold;
  font-size: 2.6rem;
  line-height: 3.8rem;
  color: #FFFFFF;
}

.link {
  margin-top: 2rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #FFFFFF;
  text-align: center;
}

.allRights {
  margin-top: 9.5rem;
  margin-bottom: 18rem;
  font-size: 1.2rem;
  color: #FFFFFF;
  text-align: center;
}

.form {
  margin: 0 auto;
  background: #FFFFFF;
  max-width: 104rem;
  width: 100%;
  height: 100%;
}

.formContent {
  padding: 5rem 8rem 6rem 8rem;
}

.toSingIn {
  width: 100%;
  text-align: center;
  margin-top: 5rem;

  .button {
    width: 22rem;
    height: 5rem;
    color: #FFFFFF;
    font-size: 1.6rem;
    font-weight: bold;
  }
}
