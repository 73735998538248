.box {
  background: #FFFFFF;
  padding: 5rem 8rem;
  margin-top: 4rem;
  border: 0.1rem solid #EFF2F7;
}

.flexContainer {
  display: flex;
  margin-top: 4rem;
}

.required {
  border-radius: 1rem;
  border: 0.1rem solid #EA397D;
  color: #EA397D;
  margin-right: 1rem;
  font-size: 1rem;
  padding: 0.2rem 0.6rem;
  position: relative;
  background: #FFFFFF;
}

.tags {
  border-radius: 2rem;
  //border: 0.1rem solid #EA397D;
  color: #FFFFFF;
  margin-right: 1rem;
  font-size: 1.4rem;
  padding: 0.8rem 3.5rem;
  position: relative;
  background: #093791;

  font-style: normal;
  font-weight: normal;
  text-align: center;
}

.tagContainer {
  margin-bottom: 1.5rem;
}


