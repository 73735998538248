.contentItem {
  display: flex;
  height: 3rem;
  align-items: center;
}
.profileImage {
  border-radius: 50%;
}

.name {
  color: #093791;
  font-size: 1.4rem;
  margin: auto 0 auto 2rem;
}

.date {
  color: #999;
  font-size: 1.2rem;
  margin: auto 0;
}

.contentItemRight {
  width: 50%;
  justify-content: space-between;
}

.backgroundMail {
  background: #f7f7f7;
}

.backgroundName {
  background: #fff;
}
.button {
  width: 0.5rem;
}

.flex {
  display: flex;
}

.marginRight {
  margin-right: 1rem;
}