.root {
  display: flex;
}
.logo {
  margin: auto;
}
.main {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 3rem 0 40rem;
  box-sizing: border-box;
}

.msgMain {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 3rem 0 0;
  box-sizing: border-box;
}

.content {
  padding: 1rem;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
}
