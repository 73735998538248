.container {
  max-width: 98.5rem;
  padding: 2rem 3.5rem 2.5rem 2rem;
  border-top: 0.05rem solid #d7e2f4;
  border-right: 0.1rem solid #d7e2f4;
  border-left: 0.1rem solid #d7e2f4;
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:last-child {
    border-bottom: 0.1rem solid #d7e2f4;
  }

  .contentBlockLeft {
    display: flex;

    .title {
      width: 50rem;

      .flex {
        display: flex;
      }

      .project {
        margin-top: 1.5rem;
      }
    }
    .tag {
      width: 9.5rem;
      margin-right: 1.029rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 2.1rem;
    }

    .profile {
      margin: auto;
    }
  }

  .contentBlockRight {
    display: flex;
    justify-content: space-between;
    width: 18rem;

    .chatBatch {
      display: flex;
      margin: auto 0;
    }

    .deadline {
      margin: auto 0;
      width: 10rem;
    }
  }
}

.commentBackground {
  background-color: #fffeee;
}

.oddBackground {
  background-color: #ffffff;
}

.evenBackground {
  background-color: #f7f9fc;
}
