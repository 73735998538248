.text {
  font-size: 1.8rem;
  font-weight: bold;
}

.icon {
  margin-right: 1rem;
}

.buttons {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
  margin-top: 4rem;
}

@media screen and (max-width: 600px){
  .buttons {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: center;
  }
}
