.background {
  background-color: #f7f9fc;
  margin-top: 3rem;
}
.container {
  max-width: 90rem;
  padding: 2rem 2rem 0;
}
.title {
  font-size: 2.2rem;
  font-weight: bold;
  color: #093791;
  margin-bottom: 0.5rem;
}

.text {
  font-size: 1.6rem;
  color: #222222;
  margin-bottom: 0.5rem;
}

.date {
  font-size: 1.4rem;
  color: #999999;
  margin-bottom: 1.5rem;
}

.nothing {
  border: 0.1rem solid #d7e2f4;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 6rem 0;
  max-width: 104rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #00a8f0;
  font-size: 2.8rem;
}
@media screen and (max-width: 600px) {
  .nothing {
    font-size: 2.1rem;
  }
}
