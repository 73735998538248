.countainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 114rem;
}

.tasksContainer {
  width: 100%;
}

.text {
  font-size: 1.6rem;
  color: #222222;
}

.title {
  padding: 1.6rem;
  color: #00a8f0;
  font-weight: bold;
  font-size: 2.6rem;
}

.subTitle {
  margin: 1.6rem auto 1.6rem 0;
}

.buttonsContainer {
  display: flex;
  padding: 4rem 0 0;
}

@media screen and (max-width: 600px) {
  .tasksContainer {
    min-width: unset;
  }
  .countainer {
    min-width: unset;
  }
}
