.errorMessage {
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #d10040;
}

.taskList {
  margin: 4rem 2rem 8rem;
}

.itemTitleAmount {
  font-size: 1.4rem;
  color: #ea397d;
}

.content {
  display: flex;
  padding-top: 1.5rem;
}

.container {
  background-color: #ffffff;
  border: 0.1rem solid #d7e2f4;
  width: 88rem;
  padding: 5rem 8rem;
}

.buttonContainer {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
}

.greyCaption {
  font-size: 1.2rem;
  color: #555555;
}

.centeringContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .taskList {
    margin: 4rem 1rem 6rem;
  }
  .container {
    background-color: #ffffff;
    border: 0.1rem solid #d7e2f4;
    width: unset;
    padding: 1.6rem;
  }
}
