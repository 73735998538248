.title {
  color: #093791;
  font-weight: bold;

  h1 {
    margin-bottom: 2rem;
    font-size: 3.6rem;
    line-height: 3.6rem;
  }
  h2 {
    font-size: 2.6rem;
    font-weight: bold;
    line-height: 3.8rem;
    padding-left: 2rem;
    border-left: 0.4rem solid #ea397d;
  }
  h3 {
    margin-bottom: 2rem;
    color: #093791;
    font-size: 2.2rem;
    line-height: 2.6rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid #093791;
  }
  h4 {
    margin-bottom: 2rem;
    color: #222;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid #ddd;
  }
}
