.container {
  background-color: #FFFFFF;
  width: 100%;
  height: 7.5rem;
  box-shadow: 0 0 0.6rem rgba(9, 55, 145, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itText {
  background-color: #FFFFFF;
  padding: 0.5rem 1rem;
  color: #093791;
  font-weight: bold;
  font-size: 2.6rem;
}

.cloudText {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 2.6rem;
  margin-left: 1rem;
}

.normalText {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 1.6rem;
}

.leadText {
  color: #093791;
  font-size: 1.8rem;
  font-weight: bold;
}

.imgContainer {
  min-width: 16rem;
}

.img {
  position: absolute;
  height: 15rem;
  top: -8rem
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 3rem;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .imgContainer {
    display: none;
  }
  .titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .itText {
    background-color: #FFFFFF;
    padding: 0.2rem 1rem;
    color: #093791;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  .cloudText {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 1.8rem;
    margin-left: 1rem;
  }
}

