.container {
  padding: 5rem;
  background: #ffffff;
  border: 0.1rem solid #d7e2f4;
  box-sizing: border-box;
  margin-top: 3rem;
  width: 100%;
}

.rowTitle {
  width: 18rem;
  min-width: 18rem;
}

.subtitleText {
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.6rem;

  color: #093791;
}

.firstRow {
  display: flex;
  align-items: center;
}

.otherRow {
  display: flex;
  margin-top: 1rem;
}

.addressSubTitle {
  width: 14rem;
}

.rowInputFullWidth {
  width: 100%;
}

.zipcodeContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.divider {
  border-bottom: 0.1rem solid #dddddd;
  width: 100%;
}

.mulchRowContainer {
  padding: 3rem 0;
}

.giveMarginLeft {
  margin-left: 1rem;
}

.marginTop {
  margin-top: 1rem;
}

.flexContainer {
  display: flex;
  align-items: center;
}

.privateMsgTitle {
  margin: auto 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: #093791;
  width: 20rem;
}

.maxWidth {
  width: 100%;
}

.titleText {
  font-style: normal;
  font-weight: bold;
  font-size: 3.4rem;
  line-height: 4.8rem;
  color: #093791;
}

.attention {
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #d10040;
}

@media screen and (max-width: 600px) {
  .titleText {
    font-size: 3rem;
  }
  .container {
    margin: 1.75rem auto 5rem;
    padding: 2rem 1.5rem 5rem;
  }
  .infoContainer {
    padding: 0rem !important;
  }
  .giveMarginLeft {
    margin-left: 0rem;
  }
  .firstRow {
    display: block;
  }
  .rowRequire {
    width: 0rem !important;
    min-width: 0rem !important;
    height: 0rem;
  }
  .requiredContainer {
    margin-bottom: 0.5rem;
  }
  .otherRow {
    display: block;
  }
  .mulchRowContainer {
    width: 30rem;
    margin: 0 auto;
    padding: 1.5rem 0;
  }
  .rowInputFullWidth {
    margin: 0.6rem 0;
  }
}
