.container {
  display: flex;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 22rem;
    padding-right: 2rem;

    p {
      color: #093791;
      font-size: 1.8rem;
      font-weight: bold;
    }

    span {
      color: #EA397D;
      font-size: 1.4rem;
      border: 0.1rem solid #EA397D;
      border-radius: 1rem;
      padding: 0 0.8rem;
    }
  }

  .inputContainer {
    flex: 1;
    margin: 1rem 0;

    input {
      font: 15px/24px sans-serif;
      box-sizing: border-box;
      width: 100%;
      border: 0.1rem solid #c7c5c5;
      border-radius: 0.4rem;
      outline: none;
      padding: 1.2rem;
      font-size: 1.8rem;
      background: #F7F7F7;

      &:focus {
        border-color: #002969;
      }
    }
  }
}
