.flex {
  display: flex;

  .container {
    width: 100%;
    background-color: #f7f9fc;

    .containerItem {
      background-color: #ffffff;
      border: 0.1rem solid #d7e2f4;
      max-width: 88rem;
      margin: 4rem auto 0;
      padding: 5rem 8rem 7rem;

      .noEvaluation {
        margin-top: 3rem;
        font-size: 2rem;
        color: #222;
        font-weight: bold;
        text-align: center;
      }

      .flexMember {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    .title {
      max-width: 104rem;
      margin: 8rem auto 4rem;
      border-bottom: 0.05rem solid #d7e2f4;
    }
  }
}

.itemContainer {
  border-bottom: 0.1rem solid #dddddd;
}

.titleContainer {
  display: flex;
  padding: 2rem 0;
  //justify-content: center;
}

.comNameContainer {
  display: flex;
  align-items: center;
  width: 17rem;
  color: #093791;
  font-size: 1.8rem;
  font-weight: bold;
}

.infoContainer {
  display: flex;
  align-items: center;
  color: #222222;
  font-size: 1.8rem;
}

.blank {
  width: 17rem;
}

.subContent {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  color: #222222;
  font-size: 1.8rem;
}

@media screen and (max-width: 600px) {
  .flex {
    display: block;
  }
  .containerItem {
    margin: 1.75rem auto 5rem !important;
    padding: 2rem 1.5rem 5rem !important;
  }

  .noEvaluation {
    margin-top: 1rem !important;
  }
  .blank {
    width: 10rem !important;
  }
  .comNameContainer {
    width: 10rem !important;
  }
}
