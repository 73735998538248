.sideQ {
  display: flex;
}

.iconButton {
  padding: 0;
}

.tooltipText {
  color: rgba(9, 55, 145, 1);
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2.4rem;
}
