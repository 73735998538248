/* --list---------------------*/
.list {
  > li {
    float: left;
    width: 35rem;
    margin-bottom: 2rem;
    margin-right: 2.5rem;
  }
  > li:nth-child(3n) {
    margin-right: 0;
  }
  .head {
    display: flex;
  }
  .company {
    padding: 0 !important;
    border-top: 0.1rem solid #d7e2f4;
    background: #f7f9fc;
    .name {
      color: #093791;
      font-size: 1.4rem;
      display: -webkit-box;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
}

@media screen and (max-width: 600px) {
  .list {
    display: block;
    li {
      width: 100%;
    }
  }
}

/* --common---------------------*/
.days {
  display: flex;
}
.date {
  margin-left: auto;
  color: #999;
  font-size: 1.2rem;
  line-height: 2.1rem;
}
.title {
  margin: 1rem 0;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.4rem;
  color: #093791;
}
.day {
  display: flex;
  line-height: 1.5;
  span {
    display: inline-block;
    background: #fff;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    padding: 0 0.8rem;
    margin-right: 1rem;
    vertical-align: middle;
  }
  .dayClosing {
    color: #222;
    margin-right: 2rem;
    span {
      border: 0.1rem solid #222;
    }
  }
  .dayPay {
    color: #ea397d;
    font-weight: bold;
    span {
      border: 0.1rem solid #ea397d;
    }
  }
}
@media screen and (max-width: 600px) {
  .day {
    display: block;
  }
}

/* --detail---------------------*/
.detail {
  section {
    margin-bottom: 2rem;
  }
  .company {
    padding: 0 !important;
    .name {
      color: #093791;
      font-size: 1.4rem;
      display: -webkit-box;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }
  .button {
    margin-top: 2rem;
    text-align: center;
  }
}

/* --mylist---------------------*/
// list
.myList {
  border: 0.1rem solid #eff2f7;
  background: #fff;
  padding: 2rem;
  .toggle {
    button {
      margin-right: 1rem;
    }
  }
  .listItem,
  li {
    border-bottom: 0.1rem solid #f7f7f7;
  }
  .listItem:last-child {
    border-bottom: none;
  }
}
