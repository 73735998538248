.container {
  background-color: #F7F9FC;
  border: 0.1rem solid #EFF2F7;
  border-radius: 0.5rem;
  padding: 3rem;
}

.contents {
  margin-top: 1rem;
}

.text {
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: #093791;
  white-space: pre-wrap;
}

.title {
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 3.2rem;
  color: #093791;
}

.imgPosition {
  position: relative;
  left: -1rem
}

.divider{
  background-color: #D7E2F4;
  width: 100%;
  height: 0.2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}