.parent {
  position: relative;
  margin: 0 auto;
}
.notificationBatch {
  position: absolute;
  height: 1.7rem;
  width: 1.7rem;
  border-radius: 1rem;
  background-color: #ff307f;
}

.left {
  left: 49rem;
  bottom: 5rem;
}
.right {
  left: 100rem;
  bottom: 5rem;
}

.containerItem {
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .left {
    left: 16rem;
    bottom: 5rem;
  }
  .right {
    left: 32rem;
    bottom: 5rem;
  }
}
