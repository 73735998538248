.fieldTitle {
  font-size: 2.2rem;
  line-height: 3.2rem;
  color: #093791;
  margin-bottom: 3rem;
}

.mainPrivateMemoHeader {
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  border: 0.1rem solid #D7E2F4;
  box-sizing: border-box;
  margin: 0;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.0rem;
  color: #093791;
}

.formContainer {
  margin-top: 3rem;
}
