.category {
  color: #222222;
  padding-top: 2.5rem;
}

.box {
  background: #ffffff;
  padding: 4rem;
  margin-top: 4rem;
  border: 0.1rem solid #eff2f7;
}

.flexContainer {
  display: flex;
  margin-top: 2rem;
}

.ratingContainer {
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
}

.ratingTitle {
  margin-right: 0.9rem;
  font-size: 1.2rem;
  color: #999999;
  font-weight: bold;
}

.required {
  border-radius: 1rem;
  border: 0.1rem solid #ea397d;
  color: #ea397d;
  margin-right: 1rem;
  font-size: 1rem;
  padding: 0.2rem 0.6rem;
  position: relative;
  background: #ffffff;
}

.tagContainer {
  margin-bottom: 5.5rem;
}

.corpName {
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #093791;
}

.privateOrPublic {
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #222222;
  mix-blend-mode: normal;
  margin: 1.5rem 0;
}

.date {
  color: #999999;
  font-size: 1.2rem;
}
