@charset "UTF-8";
.subTitle {
  padding-top: 5.3rem; }

.position {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem; }

.itemTitle {
  margin: auto 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: #093791; }

.required {
  margin-top: 1.2rem;
  margin-right: -3rem; }
  .required:before {
    content: '必須';
    padding: 0.4rem 0.7rem;
    margin-right: 3rem;
    height: 2.2rem;
    width: 4.4rem;
    border-radius: 2rem;
    border: 0.1rem solid #ea397d;
    color: #ea397d;
    font-size: 1.4rem; }

.formArea {
  width: 59.4rem; }

.formName input {
  height: 3rem;
  width: 25rem;
  font-size: 1.6rem;
  padding: 1rem 2rem; }

.fullName {
  display: flex;
  justify-content: flex-end; }

.formPhoneNumber {
  width: 100%; }

.phoneNumberForm {
  display: flex; }
  .phoneNumberForm hr {
    margin: 2.5rem 1.3rem;
    width: 1.4rem;
    border: none;
    border-top: 0.1rem #222222 solid; }

.item {
  padding: 3.5rem 0.9rem 1.5rem 3rem;
  border-bottom: #dddddd solid 0.1rem; }

.error {
  font-size: 0.8rem;
  color: red; }

.checkBox {
  display: flex;
  cursor: pointer; }
  .checkBox .checkedCircleIcon {
    width: 3.5rem;
    height: 3.5rem;
    color: #ea397d;
    margin-left: 3rem; }
  .checkBox .checkCircleIcon {
    width: 3.5rem;
    height: 3.5rem;
    color: #dddddd;
    margin-left: 3rem; }

.link {
  color: #ea397d;
  display: flex; }

.linkImage {
  width: 1.7rem;
  height: 1.4rem;
  background-image: url("../../assets/icon_link.svg");
  transform: translateY(50%);
  margin-left: 1rem;
  margin-right: 2rem; }

.required2:before {
  content: '必須';
  padding: 0.4rem 0.7rem;
  margin-right: 3rem;
  height: 2.2rem;
  width: 4.4rem;
  border-radius: 2rem;
  border: 0.1rem solid #ea397d;
  color: #ea397d;
  font-size: 1.4rem; }

.text {
  margin-top: 3rem;
  padding: 3.2rem;
  font-size: 1.8rem;
  background-color: #fff2f7;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center; }

.submit {
  margin-top: 5rem;
  text-align: center; }
  .submit input {
    display: none; }

.close {
  margin-left: 1rem; }

.submitButton {
  width: 40rem;
  font-size: 1.8rem;
  font-weight: bold;
  height: 7rem;
  border-radius: 0.8rem; }

.radioButtonContainer {
  display: flex;
  flex-direction: column; }

.paidSettings {
  width: 50rem;
  margin-top: 2rem; }

.DestinationName {
  width: 50rem; }

.formContent {
  padding: 5rem 8rem 6rem 8rem; }

.form {
  margin: 0 auto;
  background: #ffffff;
  max-width: 104rem;
  width: 100%;
  height: 100%; }

.fieldContainer {
  display: flex;
  margin: 0 auto;
  background: #ffffff;
  max-width: 104rem;
  width: 100%;
  height: 100%; }

.fieldLabel {
  display: flex;
  min-width: 12rem;
  font-size: 1.6rem;
  color: #222222;
  align-items: center; }

.lowerName {
  width: 59.4rem; }

.lowerNameBlank {
  width: 24.7rem; }

.toSingIn {
  width: 100%;
  text-align: center;
  margin-top: 5rem; }
  .toSingIn .button {
    width: 22rem;
    height: 5rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: bold; }

.aboutTask {
  margin-top: 2rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.7rem;
  text-align: center; }
  .aboutTask a {
    color: #ffffff; }

.minimumTextField {
  display: flex;
  width: 39%; }

.smallTextField {
  width: 100%; }

.normalTextField {
  width: 100%; }

.lowerNameTextField {
  width: 100%; }

.agree {
  display: flex;
  align-items: center; }

@media screen and (max-width: 600px) {
  .formContent {
    padding: 1.5rem; }
  .position {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem; }
  .fieldLabel {
    display: flex;
    min-width: 8rem;
    font-size: 1.6rem;
    color: #222222;
    align-items: center; }
  .smallTextField {
    width: 40%; }
  .normalTextField {
    width: 50%; }
  .lowerName {
    margin-left: unset;
    display: flex;
    flex-direction: column; }
  .fieldContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background: #ffffff;
    max-width: 104rem;
    width: 100%;
    height: 100%; }
  .minimumTextField {
    display: flex;
    width: 50%;
    margin-bottom: 1rem; }
  .phoneNumberForm {
    display: flex;
    flex-direction: column; }
    .phoneNumberForm hr {
      margin: 2.5rem 1.3rem;
      width: 1.4rem;
      border: none;
      border-top: 0.1rem #222222 solid; }
  .formPhoneNumber {
    width: 50%; }
  .text {
    margin-top: 3rem;
    padding: 3.2rem;
    font-size: 1.8rem;
    background-color: #fff2f7;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  .required {
    margin-top: 1.2rem;
    margin-right: -3rem;
    margin-bottom: 1rem; }
    .required:before {
      content: '必須';
      padding: 0.4rem 0.7rem;
      margin-right: 3rem;
      height: 2.2rem;
      width: 4.4rem;
      border-radius: 2rem;
      border: 0.1rem solid #ea397d;
      color: #ea397d;
      font-size: 1.4rem; } }
