.inner {
  padding: 5rem 10rem 6rem;
}

.header {
  text-align: center;
}

.button {
  display: flex;
  justify-content: center;
}

.subTitle {
  color: #00a8f0;
  font-size: 2.8rem;
  margin-top: 1.5rem;
}

.message {
  margin: 1.2rem 0 3.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 3.2rem;
  white-space: pre-wrap;
  text-align: center;
}

.image {
  width: 5rem;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowTitle {
  font-weight: bold;
}

.innerContainer {
  padding: 3rem;
}

@media screen and (max-width: 600px) {
  .subTitle {
    font-size: 2.2rem !important;
  }
  .message {
    font-size: 1.4rem !important;
  }
  .inner {
    padding: 5rem 1rem 6rem;
  }
  .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .button {
    display: block;
  }
  .innerContainer {
    padding: 1.6rem;
  }
}
