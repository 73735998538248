.menuTextEdit {
  margin-left: 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #093791;
}

.menuTextDelete {
  margin-left: 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #222222;
}

.flexItem {
  display: flex;
  align-items: center;
}

.icon {
  width: 1.6rem;
  height: 1.6rem;

}

