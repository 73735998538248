.container {
  background-color: #ffffff;
  border: 0.1rem solid #d7e2f4;
  width: 88rem;
  margin: 3rem auto 0;
  padding: 5rem 8rem 7rem;
}

.item {
  margin-bottom: 5rem;
}

.itemContainer {
  border-bottom: 0.1rem solid #dddddd;
}
.lastItemContainer {
  border-bottom: 0.1rem solid #dddddd;
}

.titleContainer {
  display: flex;
  height: 9rem;
}

.comNameContainer {
  display: flex;
  align-items: center;
  width: 17rem;
  color: #093791;
  font-size: 1.8rem;
  font-weight: bold;
}

.infoContainer {
  display: flex;
  align-items: center;
  color: #222222;
  font-size: 1.8rem;
}

@media screen and (max-width: 600px) {
  .container {
    padding: 2rem 1rem 0rem;
    width: auto;
    margin-bottom: 4rem;
  }
  .lastItemContainer {
    border: none;
  }
}
