.background {
  margin-left: 2.5rem;

  .item {
    padding: 0 1.5rem 1rem;
    display: flex;
  }

  .container {
    background-color: #F7F7F7;
    border-radius: 0.6rem;
    padding: 1rem 1.5rem;

    .flex {
      display: flex;
    }
  }
}

.text {
  width: 12rem;
  margin-top: 0.3rem;
}
