.pageTitleContainer {
  padding-top: 6.4rem;
}

.main {
  background-color: #F7F9FC;
  padding: 4rem 0 8rem;
  display: flex;
  justify-content: center;

  .inner {
    border: 0.1rem solid #D7E2F4;
    background-color: #FFF;
    max-width: 104rem;
    margin: 0 2rem;
    padding: 5rem 8rem 7rem 8rem;
  }
}

.title {
  color: #093791;
  font-size: 2.6rem;
  border-bottom: 0.2rem solid #093791;
  padding-bottom: 1rem;
  margin-bottom: 2.5rem;
}

.header {
  font-size: 2.2rem;
  margin: 2rem 0;
}

.text {
  color: #222;
  font-size: 1.6rem;
  line-height: 3.2rem;
}

.container {
  display: flex;
  justify-content: center;
}

.termsContainer {
  padding: 5rem 0;
}

.rootContainer {
  background-color: #F7F9FC;
}

@media only screen and (max-width: 600px) {
  .pageTitleContainer {
    padding-top: 5.6rem;
  }
}
