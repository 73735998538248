.main {
  width: 100%;
  max-width: 108rem;
  //margin: 0 auto;
}

.blankWidth {
  width: 20rem;
}

.Container {
  max-width: 108rem;
  background-color: #ffffff;
  padding: 5rem;
  border: 1px solid #d7e2f4;
  margin-top: 5rem;
}

.subtitle {
  margin: auto 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: #093791;
  width: 40rem;
}
.contents {
  margin: auto 0;
  font-weight: bold;
  font-size: 1.8rem;
  color: #093791;
  width: 40rem;
}

.lineContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  height: 12rem;
}
.emailContainer {
  display: flex;
}
.passwordContainer {
  display: flex;
}
@media screen and (max-width: 600px) {
  .main {
    margin-bottom: 4rem;
  }
  .Container {
    margin: 4rem 0rem 0rem 0rem;
    padding: 0.5rem;
  }
  .lineContainer {
    display: block;
    border: none;
  }
  .subtitle {
    margin: 1rem 0rem 0rem 1rem;
    width: 70%;
  }

  .contents {
    width: 90%;
    margin: auto;
    text-align: center;
  }
  .button {
    margin-top: 1rem;
  }
  .subtitleContainer {
    display: flex;
  }
  .emailContainer {
    display: block;
    margin: 2.8rem;
  }
  .passwordContainer {
    display: block;
    margin: 2.9rem;
  }
  .blankWidth {
    width: 0rem;
  }
}
