.dialog {
  padding: 6rem 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.8rem;
  line-height: 4.1rem;
  color: #00A8F0;
  margin-top: 2rem;
  margin-bottom: 3.7rem;

}

.subText {
  color: #222222;
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-bottom: 1rem;
}

.link {
  color: #093791;
  font-weight: bold;
  font-size: 1.2rem;
}

.taskBar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48rem;
  height: 2.4rem;
  background: #093791;
  border-radius: 1.6rem;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.7rem;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 1.7rem;
}

.submitDateTime {
  width: 100%;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #999999;
  margin-bottom: 1rem;
}

.taskName {
  width: 100%;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: #093791;
  margin-bottom: 1.5rem;
}

.userInfoContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
}

.username {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2.0rem;
  color: #222222;
  margin-left: 1rem;
}

.ratingContainer {
  padding: 1.6rem 2rem;
  width: 48rem;
  height: 13.4rem;
  background: #EFF2F7;
  border-radius: 0.6rem;
  margin-bottom: 2rem;
}

.divider {
  border-bottom: 0.1rem solid #DDDDDD;
  width: 100%;
  margin-bottom: 3rem;
}

.ratingTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #093791;
}

.textAreaHeader {
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #093791;
  width: 100%;
  margin-top: 1.4rem;
}

.textFormContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
