.header {
  border-bottom: 0.1rem solid #D7E2F4;
  margin-bottom: 1.5rem;
  background: linear-gradient(90deg, #093791 0%, #0078E5 100%);
  color: white;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 1.8rem;
  font-weight: bold;

  .questionMark {
    font-size: 130%;
  }
}

.answer {
  display: flex;
  line-height: 2.8rem;

  .answerMark {
    margin-right: 1rem;
    font-weight: bold;
  }
}
