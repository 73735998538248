.itemWrraper {
  width: 64rem;
}

.itemContents {
  display: flex;
  min-height: 6rem;
}

.itemTitle {
  display: flex;
  align-items: center;
  width: 17rem;
  color: #093791;
  font-size: 1.8rem;
  font-weight: bold;
}

.itemMainContent {
  display: flex;
  align-items: center;
  color: #222222;
  font-size: 1.8rem;
  max-width: 47rem;
  white-space: pre-wrap;
}

.blank {
  width: 17rem;
}

.subContent {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  color: #222222;
  font-size: 1.8rem;
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  .itemWrraper {
    width: 100%;
    margin: 0.5rem 0rem 0.5rem !important;
  }
  .itemContents {
    min-height: 6rem;
  }
}
