.dateTitle {
  margin-top: 4rem;
  margin-bottom: 0.5rem;
}

.taskTitle {
  margin-bottom: 2.8rem;
}

.container {
  padding: 3rem;
  //↓shadowとどちらがよいだろう?
  // box-shadow: 0 0 0.6rem rgba(9, 55, 145, 0.2);
  border-top: 0.05rem solid #d7e2f4;
  border-right: 0.1rem solid #d7e2f4;
  border-left: 0.1rem solid #d7e2f4;
  border-bottom: 0.1rem solid #d7e2f4;
  border-radius: 0.8rem;
  display: flex;

  .flex {
    display: flex;
    align-items: center;

    .name {
      margin-left: 1.5rem;
    }

    .image {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: 0.1rem solid #dddddd;
    }
  }

  .text {
    max-width: 53rem;
    margin-top: 1rem;
  }

  .verticalVar {
    height: 15rem;
    width: 0.1rem;
    background-color: #dddddd;
    margin-left: 3rem;
  }
}

.commentContainer {
  width: 100%;
}
@media screen and (max-width: 375px) {
  .container {
    display: block;
    padding: 1rem;
  }
  .verticalVar {
    height: 0rem !important;
  }
  .dateTitle {
    margin-top: 0.5rem;
    margin-bottom: 0rem;
  }
  .taskTitle {
    margin-bottom: 1rem;
  }
}
