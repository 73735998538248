.table {
  margin-top: 7rem;
  display: flex;
  justify-content: center;

  h3 {
    padding-left: 2.3rem;
    line-height: 6.3rem;
    font-size: 2.8rem;
    letter-spacing: 0.04em;
    color: #fff;
  }

  .company {
    margin-right: 2rem;
    position: relative;
    width: 32rem;
    height: 43rem;
    background: #00a8f0;
    border: 0.1rem solid #7ee8ff;
    box-sizing: border-box;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);

    .companyTitleBase {
      position: absolute;
      top: 0;
      right: 0;
      height: 6.3rem;
    }
  }

  .engineer {
    margin-left: 2rem;
    position: relative;
    width: 32rem;
    height: 43rem;
    background: #093791;
    border: 1px solid #346cd9;
    box-sizing: border-box;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.5);

    .engineerTitleBase {
      position: absolute;
      top: 0;
      right: 0;
      height: 6.3rem;
    }
  }

  .paper {
    width: 30rem;
    min-height: 26.5rem;
    background: #fff;
    margin-left: -1rem;
    padding: 2rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);

    .link {
      font-weight: bold;
      font-size: 1.2rem;
      color: #093791;
      margin-top: 1.5rem;
      display: flex;
      justify-content: center;
    }
  }

  .buttons {
    position: absolute;
    bottom: 0;
    font-size: 1.2rem;
    width: 32rem;
    text-align: center;
    padding-bottom: 2rem;

    a {
      font-weight: bold;
      font-style: normal;
      font-size: 1.2rem;
      line-height: normal;
      color: #ffffff;
    }
  }

  .githubInner {
    text-align: center;
    margin-top: 0.3rem;
    margin-bottom: 3.215rem;

    .githubButton {
      margin-top: 1.02rem;
      background: #91d720;
      border-radius: 0.4rem;
      width: 100%;
      font-size: 1.6rem;
      font-weight: bold;
      height: 5rem;
      text-transform: none;
    }
  }
  .githubDescription {
    color: #093791;
    font-weight: bold;
    margin-bottom: 1.1rem;
  }
  .iconCompany {
    width: 2.2rem;
    height: 2.4rem;
    margin-right: 1rem;
    background-image: url('../../assets/icon_company.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .iconEngineer {
    width: 2.2rem;
    height: 2.4rem;
    margin-right: 1rem;
    background-image: url('../../assets/icon_engineer.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}

.arrow {
  background: no-repeat url('../../assets/arrow_right.svg');
  width: 0.7rem;
  height: 1rem;
  margin-right: 0.6rem;
}

@media screen and (max-width: 600px) {
  .table {
    margin-top: 3.941rem !important;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    flex-direction: column-reverse;
  }
  .engineer {
    margin: 0 auto !important;
  }
  .company {
    margin: 3rem auto 0rem !important;
  }
  .paper {
    margin: 0 -1.06rem !important;
  }
}
