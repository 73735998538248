.container {
  display: flex;
  margin: 3rem auto;

  .title {
    color: #093791;
    font-size: 1.8rem;
    font-weight: bold;
    width: 17rem;
    margin-top: 1.2rem;
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 70.8rem;

    .body {
      color: #222222;
      font-size: 1.8rem;
      height: 7.6rem;
    }
  }
}

.border {
  background-color: #DDDDDD;
  max-width: 88rem;
  height: 0.1rem;
}
