.mainPrivateMemo {
  display: flex;
  background: #ffffff;
  border: 0.1rem solid #d7e2f4;
  box-sizing: border-box;
  padding: 1.8rem 10rem;
  border-bottom: none;
}

.privateMsgTextContainer {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #222222;
  mix-blend-mode: normal;
  width: 100%;
  margin: 0 auto;
}

.privateMsgTextItems {
  margin: auto;
}

.flexContainerPrivateMsg {
  display: flex;
  position: relative;
}

.privateMsgText {
  margin-top: 1rem;
}

.more {
  margin-left: auto;
  position: relative;
}

.moreAbsolute {
  position: absolute;
  left: 3.5rem;
  top: -1.5rem;
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #222222;
}

.date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #999999;
}

.avatar {
  position: absolute;
  left: -6rem;
  top: 2px;
}

@media screen and (max-width: 600px) {
  .flexContainerPrivateMsg {
    justify-content: flex-start;
    margin-top: 0.5rem;
  }
  .avatar {
    position: static;
    margin-right: 1.5rem;
  }
  .mainPrivateMemo {
    display: flex;
    background: #ffffff;
    border: 0.1rem solid #d7e2f4;
    box-sizing: border-box;
    padding: 1.5rem;
    border-bottom: none;
  }
}
