.menubar{
  padding: 5px 10px !important;
  color: #a1a1a1;
  background: #545050;
}
.title {
  span,svg {
    color: #fff;
    font-weight: bold;
  }
  span {
    font-size: 14px;
  }
}
.subTitle {
  font-size: 14px;
  padding-left: 3.5rem !important;
  span {
    color: #bbb8b8;
    font-size: 13px;
  }
}
.active{
  background: #171b4e;
  width: 100%;
}


