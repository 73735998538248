.container {
  width: 100%;
  max-width: 98.5rem;
  padding: 2rem 3.5rem 2.5rem 2rem;
  border-top: 0.05rem solid #d7e2f4;
  border-right: 0.1rem solid #d7e2f4;
  border-left: 0.1rem solid #d7e2f4;
  display: flex;
  justify-content: space-between;

  .contentBlockLeft {
    display: flex;

    .title {
      width: 50rem;

      .flex {
        display: flex;
      }

      .project {
        margin-top: 1.5rem;
      }
    }
    .tag {
      width: 9.5rem;
      margin-right: 1.029rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 2.1rem;
    }

    .profile {
      margin: auto;
    }
  }
}

.buttonContainer {
  position: absolute;
  padding-right: 4rem;
  width: 15rem;
}

.button {
  height: 5rem;
  margin: auto 0;
}

.taskContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:last-child {
    border-bottom: 0.1rem solid #d7e2f4;
  }
}
