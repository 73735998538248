.avatarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}

.buttonContainer {
  position: relative;
}

