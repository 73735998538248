.title {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  justify-content: space-between;
}

.titleText {
  font-style: normal;
  font-weight: bold;
  font-size: 3.4rem;
  line-height: 4.8rem;
  color: #093791;
}
@media screen and (max-width: 600px) {
  .title {
    margin-bottom: 0.5rem;
  }
  .titleText {
    font-size: 2.3rem;
  }
}
