.link {
  font-size: 1.2rem;
  padding: 0 2rem;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.linkColorNormal {
  color: #6c8ac4;
}

.linkColorWhite {
  color: #d7e2f4;
}

@media screen and (max-width: 600px) {
  .linkColorWhite {
    padding: 0.2rem;
  }
  .linkColorNormal {
    padding-top: 0.6rem;
  }
}
