.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 1rem;
  text-align: center;
  color: #e2dfdf;
  height: 10rem;
  small {
    font-size: 0.8rem;
  }
}
//.list {
//  li {
//    margin-top: 0.2rem;
//  }
//}
//@media screen and (min-width: 737px) {
//  .list {
//    display: flex;
//    width: 100%;
//    ul {
//           width: 50%;
//    }
//  }
//}
