.scrollContainer {
  overflow: scroll;
  height: calc(100vh - 12.8rem);
}

.loadingMessage {
  text-align: center;
  opacity: 0.7;
  height: 5rem;
  line-height: 5rem;
}
