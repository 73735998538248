// paper layout
.paper {
  border: 0.1rem solid #eff2f7;
  background: #fff;
  padding: 2rem;
  .submit {
    text-align: right;
  }
  @media screen and (min-width: 480px) {
    .table {
      width: 100%;
      display: flex;
      text-align: left;
      align-items: center;
      dt {
        width: 110px;
      }
      dd {
        flex: 1;
        margin-left: 2rem;
      }
    }
  }
  dt span {
    color: #093791 !important;
    font-size: 1.3rem !important;
    line-height: 1.3;
  }
  .items {
    border-bottom: 0.1rem solid #eee;
    padding-bottom: 2rem;
  }
}

// panel layout
.panel {
  padding: 2rem 0;
  .submit {
    span {
      width: 100%;
    }
  }
  label {
    color: #093791 !important;
  }
}

// common
.error {
  font-size: 0.8rem;
  color: red;
}
.submit {
  display: block;
  margin-top: 2.5rem;
  input {
    display: none;
  }
  .close {
    margin-left: 1rem;
  }
  > label span {
    font-size: 1.4rem !important;
    font-weight: bold;
    width: 22rem;
    height: 5rem;
    border-radius: 0.5rem;
  }
}
.items {
  margin: 1rem 0 1.6rem;
}

.main {
  padding: 3rem;
}

.mainCategory {
  //display: flex;
  margin-bottom: 5rem;
}

.subCategory {
  display: flex;
}

.table {
  border-collapse: collapse;
  border-bottom: solid 0.1rem #eeeeee;
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  border-bottom: solid 0.1rem #eeeeee;
  padding: 3rem;
}

.CategoryText {
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #093791;
}

.required {
  border-radius: 1rem;
  border: 0.1rem solid #ea397d;
  color: #ea397d;
  margin-right: 1rem;
  font-size: 1rem;
  padding: 0.2rem 0.6rem;
  position: relative;
  background: #ffffff;
}

.clip {
  display: flex;
}

.buttons {
  display: flex;
  margin-top: 3rem;
}
.rightContent {
  margin-left: auto;
}
.attention {
  color: #ea397d;
}

.inputFileBtnHide {
  opacity: 0;
  appearance: none;
  position: absolute;
}
.buttonRight {
  margin-left: auto;
  display: flex;
}

.zIndex {
  z-index: 0;
}

.sectionConatainer {
  margin-top: 5rem;
}

.titleInput {
  textarea {
    height: 100rem;
  }
}

.container {
  padding: 5rem;
  background: #ffffff;
  border: 0.1rem solid #d7e2f4;
  box-sizing: border-box;
}
@media screen and (max-width: 600px) {
  .container {
    padding: 2rem;
  }
}

.attachmentText {
  display: inline-block;
  margin-left: 0.5rem;
  line-height: 5rem;
  font-size: 1.5rem;
}

.attachmentItem {
  display: flex;
  margin-left: 1.25rem;
  height: 5rem;
  background-color: #ffffff;
}

.attachContainer {
  position: absolute;
  margin: 2rem;
  z-index: 2;
}

.attachmentContainer {
  left: 1.4rem;
  border: 0.1rem solid #ccc;
  margin-bottom: 2rem;
  margin-top: 2rem;
  bottom: 3rem;
  color: #093791;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 173.76%;
  z-index: 2;
}

.backgroundWhite {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}
.boxContainer {
  position: relative;
}
.nonFile {
  padding: 1.5rem;
  font-size: 1.5rem;
  color: #999999;
  background-color: #ffffff;
}
.error {
  font-size: 1.7rem;
  color: #ea397d;
}
