.listItemContainer {
  display: flex;
  align-items: center;
  min-height: 11.5rem;
  padding: 2rem;
  position: relative;
}

.items {
  margin-left: 1.6rem;
  color: #999999;
}

.fromName {
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.fromNameActive {
  color: #222222;
}

.fromNameNotActive {
  color: #999999;
}

.companyName {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.4rem;
}

.title {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin-top: 1rem;
}

.date {
  font-size: 1.2rem;
  line-height: 2.1rem;
}
