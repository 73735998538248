.background {
  background: #093791;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  text-align: center;

  .notFound {
    color: #fff;
    font-size: 1.8rem;
    line-height: 4.8rem;
    letter-spacing: 0.5em;
    border-top: 0.1rem solid #fff;
    border-bottom: 0.1rem solid #fff;
    max-width: 24rem;
    margin: 0 auto;
  }

  .header {
    color: #00a8f0;
    font-size: 2.8rem;
    margin: 4rem auto 2rem;
  }

  .text {
    color: #fff;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }

  .button {
    max-width: 30rem;
    margin: 5rem auto 0;
  }
}
