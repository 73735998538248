.main {
  display: flex;
}

.roomListContainer {
  width: 30%;
}

.messageField {
  width: 70%;
}

.maxWidth {
  width: 100%;
}

.hide {
  display: none;
}

.arrowBack {
  width: 2rem;
}

.searchContainer {
  width: 100%;
  height: 3rem;
  background: #e3e9f1;
  padding: 0.5rem;
}

.searchContainerTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: bold;
  color: #093791;
}

.loadingContainer {
  width: 100%;
  height: 3rem;
  background: #e3e9f1;
}

.BaseMsgFieldContainer {
  background-color: #EFF2F7;
  overflow: scroll;
  height: calc(100vh - 12.8rem);
}

.msgFieldLayoutPc {
  padding: 3rem;
  width: 70%;
}

.msgFieldLayoutSp {
  padding: 1rem;
  width: 100%;
  height: 100%;
}


.notAvailableWrapper {
  margin-top: 3rem;
}
