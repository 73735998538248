.starContainer {
  display: flex;
  justify-content: center;
  font-size: 5rem;
  letter-spacing: 2rem;
}
@media screen and (max-width: 600px) {
  .starContainer {
    font-size: 2.6rem !important;
    margin-left: 1.2rem;
  }
}
