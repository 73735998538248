.container {
}
.content {
  padding: 0rem 0 2rem;
  background-color: #f7f9fc;
}
.titleContainer {
  margin-bottom: 4rem;
}
.flexContainer {
  display: flex;
}
.title {
  color: #00a8f0;
  font-size: 2.6rem;
  font-style: normal;
  font-weight: bold;
  padding: 6px 12px;
  flex: 1;
}

.blank {
  flex: 1;
}

.rewardAttention {
  color: #222;
  font-size: 1.8rem;
  line-height: 3.2rem;
  white-space: pre-wrap;
  padding-top: 2rem;
  padding-left: 2.2rem;
}

.taskList {
  margin: 3rem 0;
}
.noTask {
  margin-left: 7rem;
}
@media screen and (max-width: 600px) {
  .rewardAttention {
    margin-bottom: 5rem;
  }
}
