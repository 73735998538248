.container {
  display: flex;

  .content {
    flex: 1;
    background-color: #f7f9fc;
  }
}

.main {
  max-width: 108rem;
  margin: 0 auto;

  .buttons {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;

    .button {
      width: 30rem;
      height: 17rem;
      padding: 2rem;
    }
  }
}

@media screen and (max-width: 1320px) {
  .buttons {
    justify-content: center;
  }
}
