.background {
  background: #093791;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  text-align: center;
}
.notFound {
  color: #fff;
  font-size: 3.5rem;
  line-height: 10rem;
  letter-spacing: 1em;
  border-top: 0.3rem solid #fff;
  border-bottom: 0.3rem solid #fff;
  width: 75rem;
  margin: 0 auto;
}

.header {
  color: #00a8f0;
  font-size: 2.8rem;
  margin: 4rem auto 2rem;
}

.text {
  color: #fff;
  font-size: 1.6rem;
  line-height: 3.2rem;
}

.notFoundMessage {
  width: 70rem;
  margin: 0 auto;
}
.link {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #ffffff;
  margin: 0rem auto;
  width: 30rem;
  border-radius: 0.4rem;
  padding: 1.18rem 0;
  border: 1px solid #ffffff;
  &:hover {
    cursor: pointer;
    background: #002163;
  }
}
.buttons {
  margin-top: 5.7rem;
}
@media screen and (max-width: 600px) {
  .container {
    width: 90%;
    margin: 0 auto;
  }
  .notFound {
    font-size: 1.5rem;
    width: 33rem;
    line-height: 5rem;
  }
  .notFoundMessage {
    margin: 0 auto;
    width: 30rem;
    padding-left: 1rem;
  }
  .header {
    font-size: 2.3rem;
  }
}
