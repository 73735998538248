.rowImage {
  display: flex;
  align-items: center;
  border-bottom: solid 0.1rem #eeeeee;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  position: relative;
  width: 100%;
}

.rowNickName {
  display: flex;
  align-items: center;
  border-bottom: solid 0.1rem #eeeeee;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  padding-right: 3rem;
  position: relative;
  width: 100%;
}

.noneBottomRow {
  display: flex;
  align-items: center;
}

.rowTitle {
  width: 18rem;
  min-width: 18rem;
}

.rowInput {
  width: 35%;
}

.rowInputTag {
  width: 40%;
}

.rowInputFullWidth {
  width: 100%;
}

.SubTitle {
  padding-top: 3.5rem;
}

.subtitleText {
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.6rem;

  color: #093791;
}

.uploadButtonInput {
  opacity: 0;
  position: absolute;
  font-size: 4rem;
  cursor: pointer;
  right: 0;
  top: 0;
  margin: 0;
  height: 18rem;
  border-radius: 9rem;
}

.uploadButtonInputCamera {
  opacity: 0;
  position: absolute;
  font-size: 4rem;
  cursor: pointer;
  margin: 0;
  height: 18rem;
  border-radius: 9rem;
}

.error {
  color: #d10040;
  margin: 0 0 3rem;
}

.avatarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}

.imgText {
  position: absolute;
  top: 5rem;
  padding-left: 2.5rem;
}

.nicknameText {
  padding-left: 2.5rem;
}
.link {
  color: #093791;
  font-size: 1.4rem;
  line-height: 2.6rem;
  width: 39rem;
  margin-left: 3rem;
}

.SubTitle {
  margin-top: 5rem;
}

.rowOther {
  display: flex;
  align-items: flex-start;
  border-bottom: solid 0.1rem #eeeeee;
  padding: 7rem 3rem;
  position: relative;
}

.buttonContainer {
  position: relative;
}

.marginTop {
  margin-top: 3rem;
}

.formWrapper {
  width: 100%;
  border: 0.1rem solid #d7e2f4;
  background-color: #ffffff;
}
