.container {
  padding: 5rem;
  background: #ffffff;
  border: 0.1rem solid #d7e2f4;
  box-sizing: border-box;
  margin-top: 3rem;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
