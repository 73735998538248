.buttonContainer {
  display: flex;
  align-items: center;
  line-height: initial;
  
  img {
    visibility: hidden;
     }
}

.buttonContainerWithIcon {
  display: flex;
  align-items: center;
  line-height: initial;
  // margin-left: 0.5rem;
  img {
    width: 2rem;
  }
  div {
    margin-left: 0.5rem;
  }
}
