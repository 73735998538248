.flex {
  display: flex;
}

.mainInformations {
  width: 100%;
  background-color: #f7f9fc;
}

.informationItem {
  background-color: #ffffff;
  border: 0.1rem solid #d7e2f4;
  max-width: 88rem;
  margin: 3rem auto 0;
  padding: 5rem 8rem 7rem;
}

.noEvaluation {
  margin-top: 3rem;
  font-size: 1.4rem;
  color: #222;
}

.title {
  max-width: 104rem;
  margin: 8rem auto 4rem;
  border-bottom: 0.05rem solid #d7e2f4;
}

.blank {
  width: 17rem;
}

.bigAvatar {
  display: inline-block;
  width: 100vw;
  height: 100vw;
  margin-right: 5rem;
}
.buttonContainer {
  margin-left: 1rem;
  margin-top: 1rem;
}

@media screen and (max-width: 600px) {
  .flex {
    display: block;
  }
  .informationItem {
    margin: 1.75rem auto 5rem;
    padding: 2rem 1.5rem 5rem;
  }
  .avatorContainer {
    color: black;
    display: flex;
    justify-content: center;
  }
  .buttonContainer {
    margin-left: 0rem;
    margin-top: 1rem;
  }
}
