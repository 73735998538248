.container {
  display: flex;
  font-size: 1.4rem;
  background-color: #F7F9FC;
  padding: 1rem 1.5rem 0;

  .active {
    color: #093791;
    display: flex;
  }

  .inactive {
    color: #999999;
    display: flex;
  }

  .img {
    background-image: url("../../assets/icon_home.svg");
    background-repeat: no-repeat;
    width: 1.4rem;
    height: 1.4rem;
  }

  .activeLink {
    color: #093791;;
  }

  .currentLink {
    margin-left: 0.5rem;
    color: #999999;
  }
}

.blankHorizontal {
  margin: 0 0.5rem;
}

.addFlex {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .container {
    white-space: nowrap;
    overflow: auto;
  }
}
