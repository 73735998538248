.container {
  display: flex;

  .content {
    flex: 1;
    background-color: #F7F9FC;

    .main {
      max-width: 104rem;
      margin: 0 auto;

      .contentItem {
        background-color: #FFFFFF;
        border: 0.1rem solid #D7E2F4;
        max-width: 88rem;
        margin: 3rem auto 0;
        padding: 5rem 8rem 7rem;

        .profile {
          display: flex;

          .profileImage {
            width: 20rem;
            height: 20rem;
            border-radius: 50%;
            margin-top: 6rem;
          }

          .profileText {
            flex: 1;
            padding-left: 6rem;
            margin-top: 3rem;
          }
        }
      }
    }
  }
}
