.main {
  padding: 5rem 8rem;
  background: #ffffff;
  margin-top: 4rem;
  border: 0.1rem solid #EFF2F7;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  color: #093791;
  margin: 4rem 0 0.5rem;
}

.arrow {
  width: 0.5rem
}

.innerText {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #FFFFFF;
}

.imgWrapper {
  margin-left: auto;
  position: relative;
  img {
    position: absolute;
    top: 0.8rem;
  }
}

.forFlex {
  display: flex;
  width: 30rem
}

.buttons {
  margin-top: 2rem;
}
