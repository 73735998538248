.container {
  padding: 0 1rem;
}

.content {
  padding: 2rem 1rem;
  background: #ffffff;
  border: 1px solid #d7e2f4;
  margin-left: 1rem;
  min-width: 22rem;
  position: sticky;
  top: 10rem
}

.locateCenter {
  display: flex;
  justify-content: center;
}

.itemTitle {
  font-size: 1.4rem;
  color: #999999;
}

.items {
  padding: 1rem;
}

.limit {
  color: #222222;
  font-size: 1.8rem;
  font-weight: bold;
  min-height: 2.5rem;
}

.general {
  color: #222222;
  font-size: 1.6rem;
  min-height: 2.2rem;
}

.item {
  margin: 0 1rem;
}

.activeItem {
  margin: 0 1rem;
  cursor: pointer;
}
.activeItem:hover {
  background-color: #eeeeee;
}

.staffInfoContainer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  min-height: 6.5rem;
}

.staffInfoItems {
}

.staffName {
  color: #222222;
  font-size: 1.4rem;
  font-weight: normal;
}

.staffLevel {
  color: #ea397d;
  font-size: 1.4rem;
}

.staffReview {
  color: #999999;
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.amount {
  background-color: #fdebf2;
  border-radius: 0.4rem;
  padding: 1rem;
}

.amountContent {
  font-size: 2.2rem;
  color: #ea397d;
  font-weight: bold;
  min-height: 3.1rem;
}

.reviewTitle {
  margin-right: 1rem;
}

@media screen and (max-width: 600px) {
  .container {
    padding: 0rem;
  }

  .content {
    padding: 2rem 0rem;
    background: #ffffff;
    border: 1px solid #d7e2f4;
    margin-left: 0rem;
  }
  .staffInfoContainer {
    margin-top: 0rem;
    min-height: 0rem;
  }
  .itemContainer {
    width: 25rem;
    margin: 0 auto;
  }
  .rewardItemContainer {
    width: 25rem;
    margin: 0 auto;
    background-color: #fdebf2;
    padding: 1rem;
  }
  .amount {
    background-color: inherit;
    border-radius: 0.4rem;
    padding: 0rem;
  }
}
