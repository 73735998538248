.items {
  border-top: 0.2rem solid #093791;
  margin-top: 5rem;
  padding: 3rem;
  background-color: #F7F9FC;;
}

.name {
  margin-left: 2rem;
  color: #222222;
  font-size: 1.4rem;
}

.userInfoItem {
  display: flex;
  align-items: center;
}

.date {
  color: #000;
}

.datetime {
  margin-left: 2rem;
  color: #999999;
  font-size: 1.2rem;
}

.userInfo {
  display: flex;
}

.dateContainer {
  margin-left: auto;
}

.commentContainer {
  margin-left: 8rem;
  font-size: 1.6rem;
  margin-top: 2rem;
}

.reply {
  margin-left: 6rem;
  margin-top: 3rem;
}

.commentsContainer {
  margin-bottom: 4rem;
}
