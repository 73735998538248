.flex {
  display: flex;

  .container {
    width: 100%;
    background-color: #F7F9FC;

    .title {
      margin: 0 auto;
      max-width: 104rem;
      display: flex;
      justify-content: space-between;

      .button {
        margin: auto 0;
      }
    }

    .content {
      border-top: 0.1rem solid #D7E2F4;
      border-right: 0.1rem solid #D7E2F4;
      border-left: 0.1rem solid #D7E2F4;
      max-width: 104rem;
      margin: 4rem auto 0;
    }
  }
}
