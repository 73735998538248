.fieldTitle {
  font-size: 2.2rem;
  line-height: 3.2rem;
  color: #093791;
  margin-bottom: 3rem;
}

.formContainer {
  margin-top: 3rem;
}
.messageLists {
  &:last-child {
    border-bottom: 0.1rem solid #d7e2f4;
  }
}
