.noticeWrapper {
  margin-bottom: 5rem;
}

.main {
  max-width: 104rem;
  margin: 0 auto;

}

.taskContainer {
  display: flex;
  justify-content: center;
}

.createButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.createButtonInnerContainer {
  min-width: 40rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 600px){
  .createButtonInnerContainer {
    min-width: unset;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
