.main {
  margin-top: 3rem;
  padding: 1rem;
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-bottom: 20rem;
  box-sizing: border-box;
  .content {
    max-width: 110rem;
    margin-right: auto;
    margin-left: auto;
  }
}


.menu {
  display: flex;
  align-items: center;
}
.profile {
  text-align: right;
  margin: 0 0.5rem 0 0.5rem;
  font-size: 1rem;
  color: #999;
}
.iconButton {
  padding: 0.6rem !important;
}

.menuList {
  span {
    padding-left: 2.5rem;
    height: 2rem;
  }
}
.iconMenuUser {
  background-image: url("../../assets/icon_menu_user.svg");
  background-repeat: no-repeat;
}
.iconMenuProject {
  background-image: url("../../assets/icon_menu_project.svg");
  background-repeat: no-repeat;
}
.iconMenuSetting {
  background-image: url("../../assets/icon_menu_setting.svg");
  background-repeat: no-repeat;
}
.iconMenHelp {
  background-image: url("../../assets/icon_menu_question.svg");
  background-repeat: no-repeat;
}
.iconMenuSignOut {
  background-image: url("../../assets/icon_menu_signout.svg");
  background-repeat: no-repeat;
}
