.container {
  width:100%;
  padding: 2rem 3.5rem 2.5rem 2rem;
  border-top: 0.1rem solid #d7e2f4;
  border-right: 0.1rem solid #d7e2f4;
  border-left: 0.1rem solid #d7e2f4;
  display: flex;

  .title {
    width: 50rem;

    .flex {
      display: flex;
    }

    .body {
      width: 80rem;
      color: black;
    }
  }

  .tag {
    width: 10.5rem;
    margin-right: 1.029rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2.1rem;
  }

  .taskItem {
    width: 26rem;
  }

  .reward {
    display: flex;
    width: 12rem;

    span {
      margin-top: 3.8rem;
      margin-left: 0.5rem;
      color: #ea397d;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .deadlineContainer {
    // width: 12rem;

    .deadline {
      color: #999999;
      font-size: 1.4rem;
      line-height: 1.7;
      padding: 0.5rem 1rem;
      text-align: center;
      border-radius: 1rem;
      background: #ffffff;
      border: 0.1rem solid #dddddd;
    }

    .apply {
      margin-top: 0.5rem;
      font-size: 1.2rem;
      color: #999;
      display: flex;
      justify-content: center;

      .num {
        color: #222;
        font-size: 1.6rem;
        font-weight: bold;
        margin: -0.5rem 0.2rem 0;
      }
    }
  }
}

.companyName {
  margin-top: 1rem;
}
