.flex {
  display: flex;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
}

.mainInformations {
  width: 100%;
  background-color: #f7f9fc;
}
.informationItem {
  background-color: #ffffff;
  border: 0.1rem solid #d7e2f4;
  max-width: 88rem;
  margin: 3rem auto 0;
  padding: 5rem 8rem 7rem;
}
.noEvaluation {
  margin-top: 3rem;
  font-size: 1.4rem;
  color: #222;
}

.flexMember {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.title {
  max-width: 104rem;
  margin: 8rem auto 4rem;
  border-bottom: 0.05rem solid #d7e2f4;
}

.blank {
  width: 17rem;
}

.bigAvatar {
  display: inline-block;
  width: 100vw;
  height: 100vw;
}
.githubButton {
  margin-bottom: 1rem;
}
.buttons {
  margin-left: 1rem;
}
.rightContents {
  margin-right: 5rem;
}
.coRightContents {
  margin-right: 5rem;
}
.unavailableContainer {
  background-color: #ffffff;
  border: 0.1rem solid #d7e2f4;
  max-width: 88rem;
  margin: 3rem auto 0;
  padding: 0rem 8rem;
}
.unavailable {
  color: #093791;
  font-size: 2.6rem;
  font-weight: bold;
  max-width: 88rem;
  flex: auto;
  text-align: center;
  padding: 5rem 0rem;
}
@media screen and (max-width: 375px) {
  .informationItem {
    margin: 1.75rem auto 4rem;
    padding: 2rem 1.5rem 2rem;
  }
  .flex {
    display: block;
  }
  .buttons {
    margin-top: 1rem;
  }
  .rightContents {
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .coRightContents {
    display: flex;
    margin: 0rem;
    flex-direction: column;
    align-items: center;
  }
  .a {
    color: black;
  }
}
