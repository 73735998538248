.pageTitleContainer {
  padding-top: 6.4rem;
}

.container {
  background-color: #F7F9FC;
}

.inner {
  max-width: 104rem;
  margin: 0 auto;
}

.nav {
  width: 100%;
}

@media only screen and (max-width: 766px) {
  .pageTitleContainer {
    padding-top: 5.6rem;
  }

  .inner {
    display: flex;
    flex-direction: column;
    padding: 4rem 2rem 8rem;
  }

  .main {
    margin-top: 4rem;
  }
}

@media only screen and (min-width: 767px) {
  .inner {
    display: flex;
    justify-content: space-between;
    padding: 4rem 2rem 8rem;
  }

  .nav {
    max-width: 28rem;
    height: 50rem;
  }

  .main {
    margin-left: 4rem;
    width: 70rem;
  }
}
