.downloadIconCol {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #093791;
}

.downloadIcon {
  background-image: url('../../assets/icon_download.svg');
  background-repeat: no-repeat;
  background-position: center center;
  padding: 4rem;
}

.content {
  margin-bottom: 4rem;
}
.unUploadMessage {
  font-size: 1.6rem;
  line-height: 2.8rem;
  margin-top: 2.5rem;
  color: #093791;
}
