.pageTitleContainer {
  padding-top: 6.4rem;
}

.main {
  background-color: #f7f9fc;
  padding: 7.9rem 0 8rem;
}

.tableContainer {
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 2rem;
  border-collapse: collapse;
}

@media only screen and (max-width: 600px) {
  .pageTitleContainer {
    padding-top: 5.6rem;
  }
}
