// list
.list{
  .btn {
    margin: 1rem 0;
    text-align: right;
  }
  .toggle {
    button {
      margin-right: 1rem;
    }
  }
}

.editTitle {
  margin-bottom: 3rem;
}
