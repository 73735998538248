.defalt {
  color: #222;
  font-size: 1.4rem;
  line-height: 2.7rem;
}
.h1 {
  margin-top: 3rem;
  color: #093791;
  font-size: 1.8rem;
  font-weight: bold;
}
.h2 {
  margin-bottom: 2rem;
  color: #222;
  font-size: 1.6rem;
  line-height: 2.8rem;
}
.h3 {
  margin-top: 1.5rem;
  color: #222;
  font-size: 1.4rem;
  line-height: 2.7rem;
}
.h4 {
  margin-bottom: 2rem;
  color: #555;
  font-size: 1.2rem;
  line-height: 2.1rem;
}
.text {
  color: #222;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 1.4rem;
}
.caption {
  margin-bottom: 2rem;
  color: #d10040;
  font-size: 1.2rem;
  line-height: 2.1rem;
}

.text01 {
  color: #999999;
  font-size: 1.2rem;
}

.text02 {
  color: #093791;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  max-width: 50rem;
  margin-top: 11px;
}

.text03 {
  color: #ea397d;
  font-size: 1.8rem;
  font-weight: bold;
}

.text04 {
  color: #707070;
  font-size: 1.4rem;
}

.text05 {
  color: #222222;
  font-size: 1.8rem;
  font-weight: bold;
}

.text06 {
  color: #222222;
  font-size: 1.2rem;
  font-weight: bold;
}

.text07 {
  color: #222222;
  font-size: 1.2rem;
  line-height: 21px;
}

.text08 {
  color: #222;
  font-size: 1.4rem;
}

.text09 {
  color: #222;
  font-size: 1.4rem;
  font-weight: bold;
}
.text10 {
  color: #999999;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 26px;
  max-width: 50rem;
  margin-top: 11px;
  margin-right: 0.5rem;
}
.text11 {
  color: #ea397d;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 6.4em;
  margin: 0 1rem;
}
