.container {
  width: auto;
  padding: 1.8rem;
  border-left: 0.1rem solid #d7e2f4;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 600px) {
  .container {
    width: auto;
    padding: 1.8rem;
    border-left: 0.1rem solid #d7e2f4;
    display: block;
  }
  .title {
    width: 100% !important;
    padding-left: 0.7rem;
  }
  .taskItemDetail {
    display: block !important;
    width: 100% !important;
  }
  .deadlineContainer {
    min-width: 12.5%;
    margin-top: 1rem;
    width: 10rem;
  }
  .deadline {
    width: 8rem !important;
    margin: 0 auto !important;
  }
  .apply {
    margin: 0 auto !important;
  }
  .expired {
    text-align: left !important;
    margin-top: 0rem !important;
  }
}
.title {
  width: 50%;
}
.flex {
  display: flex;
  align-items: center;
}

.body {
  width: 80rem;
  color: black;
}

.tag {
  width: 10.1rem;
  margin-right: 1.029rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.1rem;
}

.taskItemDetail {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.taskInfo {
  margin-top: 1.2rem;
  margin-bottom: 0.5rem;
}

.deadlineContainer {
  min-width: 12.5%;
}

.deadline {
  color: #999999;
  font-size: 1.45rem;
  line-height: 1.7;
  padding: 0 0.6rem;
  border-radius: 1.5rem;
  background: #ffffff;
  border: 0.1rem solid #dddddd;
  text-align: center;
  width: 9rem;
}

.apply {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  color: #999;
  display: flex;
  justify-content: center;
}
.num {
  color: #222;
  font-size: 1.6rem;
  font-weight: bold;
  margin: -0.5rem 0.2rem 0;
}

.companyName {
  margin-top: 1rem;
}

.expired {
  color: #999999;
  margin-top: 3rem;
  font-weight: bold;
  font-size: 2rem;
  padding-left: 1rem;
  text-align: center;
}
