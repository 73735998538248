.submit {
  display: block;
}

.textSubmit {
  margin-left: auto;
}

.input {
  display: none;
}

.close {
  margin-left: 1rem;
}

> label span {
  font-size: 1.4rem !important;
  font-weight: bold;
  width: 30rem;
  height: 5rem;
  border-radius: 0.5rem;
}

.submits {
  display: flex;
  margin-left: auto;
}

.items {
  //margin: 1rem 0 1.6rem;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .buttonsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    align-items: center;
  }
  .buttons {
    display: block;
    margin-top: 3rem;
  }
  .submit {
    margin-top: 1.5rem;
    margin-left: 0rem;
  }
}
