.root {
  flex-grow: 1;
}
.appbar {
  background-color: #fff !important;
}
.headerButton {
  height: 3rem;
  border-radius: 10rem !important;
}
.logo {
  margin: auto;
}
.logoContainer {
  display: flex;
  width: 100%;
  position: absolute;
}
.logoContainerForAnonymous {
  display: flex;
  width: 100%;
  position: absolute;
}
.menu {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 1rem;
}
.profile {
  text-align: right;
  margin: 0 0.5rem 0 0.5rem;
  font-size: 1rem;
  color: #999;
}
.iconButton {
  padding: 0.6rem !important;
}
.rightIconButton {
  padding: 0.6rem 1.2rem 0.6rem 1.2rem !important;
  height: 4.8rem;
}

@media screen and (max-width: 600px) {
  .rightIconButton {
    padding: 0 0.1rem !important;
  }
}
.IconBell {
  width: 3rem;
  height: 3rem;
}

.menuList {
  span {
    padding-left: 2.5rem;
    height: 2rem;
  }
}

.maxWidth {
  width: 100%;
}

.listItemContainer {
  display: flex;
  align-items: center;
  padding: 0.8rem;
}

.menuText {
  font-size: 1.2rem;
  margin-left: 1rem;
}

.contextMenuNickName {
  font-size: 1.4rem;
  color: #999999;
  padding: 0.8rem;
  font-weight: bold;
}
.sidemenu {
  position: absolute;
  left: 1.5rem;
}

.betaLogoContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.betaLogo {
  border: 0.1rem solid #D7E2F4;
  border-radius: 0.2rem;
  color: #093791;
  font-size: 1.2rem;
  padding: 0 0.3rem;
  margin-left: 1rem;
}

@media screen and (max-width: 600px) {
  .logoContainerForAnonymous {
    display: flex;
    width: 100%;
    position: relative;
  }
}
