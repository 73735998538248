.main {
  padding: 3rem;
}

.required {
  border-radius: 1rem;
  border: 0.1rem solid #ea397d;
  color: #ea397d;
  margin-right: 1rem;
  font-size: 1rem;
  padding: 0.2rem 0.6rem;
  position: relative;
  background: #ffffff;
}

.notRequired {
  visibility: hidden;
}

.rowRequire {
  width: 6rem;
  min-width: 6rem;
}

.submit {
  display: block;
  margin-top: 2.5rem;
  margin-left: 1rem;

  .close {
    margin-left: 1rem;
  }

  > label span {
    font-size: 1.4rem !important;
    font-weight: bold;
    width: 30rem;
    height: 5rem;
    border-radius: 0.5rem;
  }
}
.submitButton {
  color: #093791;
  width: 20rem;
  height: 5rem;
  font-size: 1.8rem;
  font-weight: bold;
  border-color: #093791;
}

.submits {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .main {
    padding: 2rem;
  }
}
