.main {
  padding: 3rem;
  background-color: #ffffff;
}

.row {
  display: flex;
  align-items: center;
}
.itemNameContainer {
  display: flex;
}
.contentContainer {
  padding: 0 3rem;
}

.itemPadding {
  padding: 3rem;
}

.itemBorder {
  border-bottom: solid 0.1rem #eeeeee;
}

.rewardItemContainer {
  display: flex;
}

.rewardDetail {
  min-width: 6rem;
}

.yenText {
  margin: 0 0 0 1rem;
}

.rewardDetailTitle {
  min-width: 18rem;
}

.sumDivider {
  height: 0.1rem;
  background-color: #999999;
}

.rowOverview {
  display: flex;
}

.rowEnv {
  display: flex;
  align-items: center;
  padding: 3rem 3rem 2rem 3rem;
}

.rowSubCategory {
  display: flex;
  align-items: center;
  padding: 3rem 3rem 2rem 3rem;
}

.rowTitle {
  width: 18rem;
  min-width: 18rem;
}

.rowInput {
  width: 100%;
}

.rowInputReward {
  display: flex;
  align-items: center;
  color: #222222;
  font-size: 1.6rem;
  p {
    margin-left: 1rem;
  }
}

.rowInputTag {
  width: 40%;
}

.rowInputFullWidth {
  width: 100%;
}

.rowInputSchedule {
  width: 16rem;
}

.rowRequire {
  width: 6rem;
  min-width: 6rem;
}

.subCategoryContainer {
  //border-bottom: solid 0.1rem #eeeeee;
}

.attachedContainer {
  display: flex;
  align-items: center;
  //border-bottom: solid 0.1rem #eeeeee;
}

.inputOther {
  margin-left: 24rem;
  padding: 0rem 3rem 3rem 3rem;
}

.notRequired {
  visibility: hidden;
}

.attachButtonComment {
  font-size: 1.2rem;
  color: #999999;
}

.leftBlankContent {
  margin-left: 27rem;
  padding: 0 3rem 2rem 0;
  width: 30rem;
}

.fileExplain {
  color: #222222;
  font-size: 1.4rem;
  margin: 2rem 0 1rem;
}

.divider {
  margin: 3rem 0;
}

.plusButtonContainer {
  margin: 3rem 0;
}

.plusButtonContainerTag {
  margin: 3rem 0 3rem 2rem;
}

.fileExplainInput {
  margin: 0 0 3rem;
}

.urlExplain {
  color: #222222;
  font-size: 1.4rem;
  margin: 0 0 1rem;
}

.required {
  border-radius: 1rem;
  border: 0.1rem solid #ea397d;
  color: #ea397d;
  margin-right: 1rem;
  font-size: 1rem;
  padding: 0.2rem 0.6rem;
  position: relative;
  background: #ffffff;
}

.CategoryText {
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #093791;
}

.submit {
  display: block;
  margin: 2.5rem 1.6rem 1.6rem 1.6rem;

  .close {
    margin-left: 1rem;
  }

  > label span {
    font-size: 1.4rem !important;
    font-weight: bold;
    width: 30rem;
    height: 5rem;
    border-radius: 0.5rem;
  }
}

.submits {
  display: flex;
  justify-content: center;
}

.table {
  border-collapse: collapse;
  border-bottom: solid 0.1rem #eeeeee;
  width: 100%;
}

.mainCategory {
  //display: flex;
  margin-bottom: 5rem;
}

.datetimeFont {
  color: #222222;
  font-size: 1.6rem;
  margin: 0 2rem;
}

.scheduleContainer {
  //margin-bottom: 5rem;
}

.buttonText {
  margin-left: 1rem;
  font-size: 1.6rem;
  color: #ffffff;
  font-weight: bold;
}

.uploadButtonInput {
  opacity: 0;
  position: absolute;
  font-size: 4rem;
  cursor: pointer;
  right: 0;
  top: 0;
  margin: 0;
}

.tagContainer {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.tagContainers {
  display: flex;
  margin-bottom: 1.5rem;
}

.tags {
  border-radius: 2rem;
  //border: 0.1rem solid #EA397D;
  color: #ffffff;
  margin-right: 1rem;
  font-size: 1.4rem;
  padding: 0.8rem 3.5rem;
  position: relative;
  background: #093791;

  font-style: normal;
  font-weight: normal;
  text-align: center;
}

.clipImg {
  width: 2rem;
}

.attachmentContainer {
  width: 50%;
}

.attachmentItem {
  display: flex;
  margin: 1rem 0;
}

.attachmentText {
  display: inline-block;
  margin-left: 0.5rem;
}

.error {
  color: #ea397d;
  font-size: 1.7rem;
  margin-left: 1.4rem;
}

.mainContent {
  border: 0.1rem solid #d7e2f4;
}

.radioLabelText {
  font-size: 1.6rem;
}

.couponDescription {
  font-size: 1.2rem;
  color: #555555;
}
.couponAttention {
  font-size: 1.2rem;
  color: #ea397d;
  font-weight: normal;
}

@media screen and (max-width: 600px) {
  .submit {
    display: block;
    margin: 0.5rem;

    .close {
      margin-left: 1rem;
    }

    > label span {
      font-size: 1.4rem;
      font-weight: bold;
      width: 30rem;
      height: 5rem;
      border-radius: 0.5rem;
    }
  }
  .submits {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2.4rem;
  }
  .main {
    padding: 2rem 2rem 1rem 2rem;
  }
  .row {
    display: block;
  }
  .itemPadding {
    padding: 1rem 1rem 3rem 1rem;
  }
  .itemNameContainer {
    justify-content: space-between;
    margin-bottom: 0.8rem;
  }
  .rowRequireContainer {
    display: flex;
    flex-direction: row-reverse;
  }
  .rowRequire {
    min-width: 0rem;
    padding-left: 1rem;
    width: 4rem;
  }
  .required {
    margin-right: 0rem;
  }
  .rowOverview {
    display: block;
  }
  .attachedContainer {
    display: block;
    width: 90%;
  }
  .attachmentContainer {
    display: block;
    width: 90%;
    padding: 0rem 1rem 1rem 1rem;
  }
  .rowSubCategory {
    display: block;
    padding: 2rem 1rem 1rem 1rem;
  }
  .attachmentItem {
    display: flex;
    margin-top: 0rem;
  }
  .attachmentText {
    width: 90%;
    margin-left: 2rem;
    max-width: 16rem;
  }
  .rowInputTag {
    width: 100%;
  }
  .leftBlankContent {
    margin-left: 1rem;
    padding: 0rem 1rem 1rem 1rem;
  }
  .nonTitleContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
  }
}
