.container {
  .content {
    padding: 4rem 0 8rem;
    background-color: #f7f9fc;

    .newsList {
      margin-bottom: 6rem;
    }
  }
}
@media screen and (max-width: 600px) {
  .content {
    padding: 4rem 0 5rem !important;
  }
  .newsList {
    margin-bottom: 0rem !important;
  }
}
