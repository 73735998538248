.container {
  background-color: #ffffff;
  width: 70rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
}

.subTitle {
  color: #00a8f0;
  font-size: 2.8rem;
  margin-top: 1.5rem;
}

.text {
  color: #222;
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.textContainer {
  margin-top: 4rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.memberMail {
  width: 100%;
  padding: 1.5rem 2rem;
  font-size: 1.6rem;
  border: 0.1rem solid #dddddd;
  border-radius: 0.4rem;
}

.inputItem {
  width: 80%;
}

.button {
  margin-top: 4rem;
}
.errorMessage {
  color: #ea397d;
  padding-left: 2.1rem;
}
