.inner {
  width: 50rem;
  padding: 5rem 10rem 6rem;
}
.header {
  text-align: center;
}
.subTitle {
  color: #00a8f0;
  font-size: 2.8rem;
  margin-top: 1.5rem;
}

.button {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.emailList {
  margin-top: 4.5rem;
}
.mailItem {
  display: flex;
  justify-content: space-between;
}
.addedEmail {
  display: flex;
}
.emailText {
  margin: auto 0 auto 1.7rem;
}
.emailError {
  margin: auto 0 auto 3.5rem;
  color: #ea397d;
}
.entireError {
  width: 70rem;
  margin-top: 3rem;
  color: #ea397d;
}

.form {
  display: flex;
  justify-content: center;
  margin-top: 3.5rem;
}
.inputItem {
  width: 37rem;
}
.memberMail {
  width: 100%;
  padding: 1.5rem 2rem;
  font-size: 1.6rem;
  border: 0.1rem solid #dddddd;
  border-radius: 0.4rem;
}

.addButton {
  width: 8rem;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}
