.background {
  display: flex;
  flex-direction: column;
  background-color: #00A8F0;
  width: 24rem;
  position: fixed;

  a {
    width: 24rem;
    color: #FFFFFF;
  }
}

.underBackground {
  background-color: #00A8F0;
  min-width: 24rem;
}
