.list {
  li {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.projectTitle {
  position: relative;
  padding-top: 1rem;
  margin: 0rem 2rem 1rem 0.5rem;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.6rem;
  color: #093791;
}

.container {
  width: auto;
  height: auto;
  display: flex;
  justify-content: space-around;
}

.category {
  display: flex;
  align-items: center;
  color: #999999;
}
.categoryItem {
  display: inline-block;
  padding: 0.6rem 1.5rem;
  border-radius: 1.4rem;
  text-align: center;
  font-weight: bold;
  margin: 0 0.5rem;
  color: #999999;
  border: 0.05rem solid #dddddd;
}
.tasks {
  width: 10rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.6rem;
  color: #222222;
}

.description {
  width: 30rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.6rem;
  color: #999999;
  margin: 1.3rem 2rem 0.9rem 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.categoryArrow {
  color: #555555;
  font-weight: bold;
  margin: 0rem 0.7rem;
  display: flex;
}
.arrowImg {
  width: 0.5rem;
}
.cardContentImg {
  margin-left: 95%;
  position: absolute;
  top: 50%;
  margin-top: -1.2rem;
}
.cardArrow {
  width: 1.5rem;
}
@media screen and (max-width: 600px) {
  .projectTitle {
    font-size: 2rem;
    padding-top: 0rem;
  }
  .cardContentImg {
    margin-left: 90.5%;
    position: absolute;
    top: 55%;
    margin-top: -1.2rem;
  }
  .cardArrow {
    width: 1.3rem;
  }
}
