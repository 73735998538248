.border {
  border: 0.1rem solid #D7E2F4;
}

.record {
  @extend .border;

  .header {
    @extend .border;
    padding: 1rem 0;
    background-color: #EFF2F7;
  }

  .data {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 1rem 2rem;
  }
}
