.background {
  display: flex;
  flex-direction: column;
  background-color: #093791;
  width: 24rem;
  position: fixed;
}

.underBackground {
  background-color: #093791;
  min-width: 24rem;
}
