.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.buttonItem {
  width: 50%;
}
