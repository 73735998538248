.submit {
  display: block;
  text-align: right;
  margin-left: auto;

  input {
    display: none;
  }

  .close {
    margin-left: 1rem;
  }

  > label span {
    width: 16rem;
    font-size: 1.6rem ;
    font-weight: bold;
    height: 4rem;
    border-radius: 0.4rem;
  }
}

.underTextArea {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.greyText {
  font-size: 1.2rem;
  color: #999999;
}
