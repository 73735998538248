.inner {
  width: 50rem;
  padding: 5rem 10rem 6rem;
}
.header {
  text-align: center;
}

.subTitle {
  color: #00a8f0;
  font-size: 2.8rem;
  margin-top: 1.5rem;
}

.button {
  text-align: center;
  margin-top: 5rem;
  width: 50rem;
}

.emailList {
  margin-top: 4.5rem;
}

.mailItem {
  display: flex;
  justify-content: space-between;
}

.addedEmail {
  display: flex;
}

.emailText {
  margin: auto 0 auto 1.7rem;
}

.form {
  display: flex;
  justify-content: space-between;
  margin-top: 3.5rem;
}

.inputItem {
  width: 37rem;
}

.memberMail {
  width: 100%;
  padding: 1.5rem 2rem;
  font-size: 1.6rem;
  border: 0.1rem solid #dddddd;
  border-radius: 0.4rem;
}

.addButton {
  width: 8rem;
}

.container {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid #d7e2f4;
  padding: 3rem;
}

.contentItem {
  display: flex;
}

.profileImage {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.name {
  color: #093791;
  font-size: 1.4rem;
  margin: auto 0 auto 2rem;
}

.date {
  color: #999;
  font-size: 1.2rem;
  margin: auto 0;
}

.contentItemRight {
  width: 50%;
  justify-content: space-between;
}

.backgroundMail {
  background: #f7f7f7;
}

.backgroundName {
  background: #fff;
}
