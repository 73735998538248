.container {
  background-color: #fff2f7;
  border: 0.1rem solid #fff2f7;
  border-radius: 0.5rem;
  padding: 3rem;
}
.iconContainer {
  display: flex;
}
.iconMessage {
  padding-top: 0.5rem;
  text-align: center;
  color: #ea397d;
  letter-spacing: 0.4rem;
  font-size: 2rem;
}
.contents {
  margin-top: 1rem;
}

.text {
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: #ea397d;
  white-space: pre-wrap;
  margin-bottom: 1rem;
}

.title {
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 3.2rem;
  color: #ea397d;
}

.imgPosition {
  position: relative;
  left: -1rem;
  width: 3.1rem;
}

.divider {
  background-color: #f4d7e8;
  width: 100%;
  height: 0.2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
