.main {
  background: #ffffff;
  border: 0.1rem solid #d7e2f4;
  box-sizing: border-box;
  padding: 2rem;
  min-width: 80rem;
}

.mainPrivateMemo {
  display: flex;
  background: #ffffff;
  border: 0.1rem solid #d7e2f4;
  box-sizing: border-box;
  padding: 2rem 10rem;
  border-bottom: none;

  &:last-child {
    border-bottom: 0.1rem solid #d7e2f4;
  }
}

.mainPrivateMemoHeader {
  display: flex;
  justify-content: center;
  background: #ffffff;
  border: 0.1rem solid #d7e2f4;
  box-sizing: border-box;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #093791;
}

.Tabs {
  margin-top: 4rem;
}

.privateMsgTitle {
  line-height: 3.8rem;
  margin-left: 1rem;
}

.pastPrivateMsgTxt {
  font-size: 1.4rem;
  margin-left: 1rem;
}

.mainTitleContainer {
  min-height: 4.8rem;
}

.flexContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.flexContainerPrivateMsg {
  display: flex;
  position: relative;
}

.privateMsg {
  margin: 2rem 0;
  position: relative;
}

.attachmentContainer {
  position: absolute;
  left: 1.4rem;
  top: 1.4rem;
  color: #093791;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 173.76%;
  z-index: 1;
}

.attachmentItem {
  display: flex;
}

.attachmentText {
  display: inline-block;
  margin-left: 0.5rem;
}

.avatar {
  position: absolute;
  left: -5rem;
  top: 2px;
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #222222;
}

.date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #999999;
}

.privateMsgTextContainer {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #222222;
  mix-blend-mode: normal;
  width: 100%;
  margin: 0 auto;
}

.privateMsgTextItems {
  margin: auto;
}

.privateMsgText {
  margin-top: 1rem;
}

.more {
  margin-left: auto;
  position: relative;
}

.moreAbsolute {
  position: absolute;
  left: 3.5rem;
  top: -1.5rem;
}

.contents {
  display: flex;
  justify-content: space-around;
  max-width: 108rem;
  margin: 4rem auto 0;
}

.titleContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.addFlex {
  display: flex;
}

.iconMargin {
  margin-right: 0.5rem;
}

.errorMessage {
  font-size: 1.6rem;
  color: #d10040;
  margin-bottom: 2.5rem;
}

.blank {
  height: 3rem;
}

.workMemoContainer {
  width: 95%;
}

@media screen and (max-width: 600px) {
  .main {
    padding: 1rem;
    min-width: auto;
  }

  .pastPrivateMsgTxt {
    font-size: 1.4rem;
  }

  .contents {
    display: block;
    justify-content: space-around;
  }

  .workMemoContainer {
    width: 95%;
    margin: 0 auto;
  }
}
