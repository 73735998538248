.backGround {
  background-color: #ffffff;
  border: 0.1rem solid #EFF2F7;
}

.userInfo {
  padding: 5rem 8rem;
}

.header {
  color: #093791;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.tableBody {
  tr {
    padding-top: 0;
  }
}

.tableRows {
  display: inline-block;
  vertical-align: top;
  width: 70%;
}

.main {
  margin-top: 4rem;
  height: 100%;
}



