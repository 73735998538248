.rewardContainer {
  min-width: 12.5%;
  padding: 1rem;
}

.reward {
  display: flex;
  //background-color: #fdebf2;
  padding: 0 1rem;
  border-radius: 7%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
@media screen and (max-width: 600px) {
  .rewardContainer {
    min-width: 12.5%;
    padding: 0rem;
  }
  .reward {
    display: block;
    padding: 0 1rem;
    border-radius: 7%;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
}
.unit {
  width: 100%;
  margin-left: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.itemTitleAmount {
  font-size: 1.4rem;
}

.red {
  color: #ea397d;
}

.blue {
  color: #093791;
}

.price {
  font-size: 1.8rem;
  font-weight: bold;
}

.rewardItem {
  display: flex;
  align-items: center;
}
