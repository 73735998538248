.list {
  li {
    margin-bottom: 1.5rem;
  }
}

.requestButton {
  margin-left: auto;
  margin-bottom: auto;
}

.dialogContentText {
  min-width: 20rem;
}

.cardList {
  li {
    margin-top: 1rem;
  }
  .cardContent {
    padding: 0 16px 16px !important;
  }
  .actionLink {
    margin-left: auto !important;
  }
}

.avatarsContainer {
  display: flex;
  align-items: center;
  width: 40%;
  cursor: pointer;
}

.username {
  color: #093791;
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: bold;
}

.prContainer {
  // height: auto;
  // display: flex;
  // flex-direction: column;
}

.entryInfo {
  display: flex;
  align-items: center;
}
.eval {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.tags {
  border-radius: 2rem;
  //border: 0.1rem solid #EA397D;
  color: #093791;
  //margin-right: 1rem;
  font-size: 1.4rem;
  padding: 0.1rem 2rem;
  position: relative;
  //background: #093791;

  border: 0.1rem solid;
}

.tagContainer {
  margin: 0 1rem 0.5rem 0;
}

.tagItems {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pr {
  margin-top: 2rem;
  color: #222222;
  font-size: 1.2rem;
  line-height: 2.1rem;
}
.wholeEval {
  color: #222222;
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-weight: bold;
}

.rating {
  margin: 0 2rem;
}

.achievement {
  color: #093791;
  font-size: 1.2rem;
  line-height: 2.1rem;
  margin-left: 2rem;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.innerContainer {
  display: flex;
}

.prText {
  margin-top: 2rem;
  color: #222222;
  font-size: 1.2rem;
  line-height: 2.1rem;
  width: 70rem;
  white-space: nowrap;
}
