.container {
  background-color: #FFFFFF;
  border: 0.1rem solid #D7E2F4;
  width: 88rem;
  margin: 3rem auto 0;
  padding: 5rem 8rem 7rem;
}

.itemContainer {
  border-bottom: 0.1rem solid #DDDDDD;;
}

.titleContainer {
  display: flex;
  height: 9rem;
}

.comNameContainer {
  display: flex;
  align-items: center;
  width: 28rem;
  color: #093791;
  font-size: 1.8rem;
  font-weight: bold;
}

.infoContainer {
  display: flex;
  width: 100%;
  align-items: center;
  color: #222222;
  font-size: 1.8rem;
}

.sumText {
  font-weight: bold;
}

.confirmContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  color: #D10040;
  font-size: 1.8rem;

}

.confirmTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
}

@media screen and (max-width: 600px) {
  .container {
    background-color: #FFFFFF;
    border: 0.1rem solid #D7E2F4;
    width: unset;
    margin: 3rem auto 0;
    padding: 3rem 1.6rem;
  }
}
