.container {
  justify-content: center;

  .taskList {
    margin: 4rem auto;
    border-bottom: 0.1rem solid #d7e2f4;
  }
}

.titleContainer {
  margin-bottom: 4rem;
}

.countContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.countItemContainer {
  display: flex;
  align-items: baseline;
}

.switchContainer {
  display: flex;
  align-items: center;
  background-color: #efefef;
  border-radius: 2rem;
  padding: 0 2rem;
}

.countText {
  font-size: 2.2rem;
  font-weight: bold;
  color: #ea397d;
  margin: 0 0.5rem;
}
