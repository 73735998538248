.messageContainer {
  display: flex;
  height: calc(100vh - 6.4rem);
  background-color: #f7f9fc;
}

.messageContent {
  flex: 1;
  background-color: #f7f9fc;
  padding: 0;
}

.normalContainer {
  display: flex;
  min-height: calc(100vh - 6.4rem);
  background-color: #f7f9fc;
}

.normalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background-color: #f7f9fc;
  padding: 0 1rem;
}

.dontUseContainer {
  flex: 1;
  background-color: #f7f9fc;
}

.blank {
  width: 100%;
  height: 6.4rem;
}

.subNormalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.subMessageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inner {
  max-width: 104rem;
  width: 100%;
  padding-bottom: 2rem;
  margin: 3rem auto 8rem;
}

.content {
  min-height: 100vh;
}
@media screen and (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0rem;
  }
  .inner {
    margin: 3rem auto 0;
    padding: 0 1.6rem;
  }
}
