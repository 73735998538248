.flex {
  display: flex;
  justify-content: space-between;

  .title {
    color: #093791;
    font-size: 2.6rem;
    font-weight: bold;
  }
}

.border {
  background-color: #093791;
  height: 0.2rem;
  max-width: 88rem;
  margin-top: 1rem;
}
