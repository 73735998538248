.box {
  background: #FFFFFF;
  padding: 5rem 8rem;
  margin-top: 4rem;
  border: 0.1rem solid #EFF2F7;
}

.rootflexBox {
  display: flex;
}

.rightBox {
  margin-top: 4rem;
  margin-left: 6rem;
}

.langAndLevel {
  margin-right: 4rem;
  margin-bottom: 1rem;
  background: #F7F7F7;
}

.upperBox {
  padding: 0.5rem 4rem 0.5rem 0.5rem;
  width: 20rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  color: #093791;
}

.lowerBox {
  padding: 1rem;
  text-align: center;
}

.lv {
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  color: #093791;
}

.levelNum {
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  text-align: center;
  color: #093791;
}

.main {
  display: flex;
  margin-top: 4rem;
  margin-bottom: 2.5rem;
}

.logos {
  img {
    display: block;
    margin-bottom: 1rem;
  }
  margin-right: 2rem;
}

.levelText {
  font-style: normal;
  font-weight: bold;
  font-size: 7rem;
  line-height: 5rem;

  color: #093791;
}

.level {
  display: flex;
}

.nextLevelSection {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  color: #093791;
}

.nextLevelContent {
  margin-bottom: 2rem;
}

.bottomLink {
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  color: #093791;
}

.bottomLinkWrapper {
  margin-top: 2rem;
  text-align: right;
}

.flexBoxLang {
  display: flex;
  flex-wrap: wrap;
}

.progress {
  background: #EEEEEE;
  border-radius: 1rem;
  width: 20rem;
  height: 0.8rem;
}

.progressOver {
  background: #093791;
  border-radius: 1rem;
  width: 10rem;
  height: 0.8rem;
}
