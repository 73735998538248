@charset "UTF-8";
.header {
  color: #093791;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.5rem; }

.main {
  display: flex;
  align-items: center;
  /*上下中央揃え*/ }
