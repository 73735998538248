// paper layout
.paper {
  border: 0.1rem solid #eff2f7;
  background: #fff;
  padding: 2rem;
  @media screen and (min-width: 480px) {
    .table {
      width: 100%;
      display: flex;
      text-align: left;
      align-items: center;

      dt {
        width: 110px;
      }

      dd {
        flex: 1;
        margin-left: 2rem;
      }
    }
  }

  dt span {
    color: #093791;
    font-size: 1.3rem;
    line-height: 1.3;
  }

  .items {
    border-bottom: 0.1rem solid #eee;
    padding-bottom: 2rem;
  }
}

.error {
  color: #ea397d;
  line-height: 2.6rem;
  font-size: 1.2rem;
}

.submit {
  display: block;
  text-align: center;

  input {
    display: none;
  }

  .close {
    margin-left: 1rem;
  }

  > label span {
    width: 30rem;
    font-size: 1.4rem;
    font-weight: bold;
    height: 5rem;
    border-radius: 0.4rem;
  }
}

.items {
  label {
    color: #999999;
    padding: 1.5rem 0 0.8rem;
    font-size: 1.2rem;
    font-weight: bold;

    i {
      margin-right: 0.8rem;
      display: inline-block;
    }
  }

  .inputField {
    input {
      height: 3.9rem;
      padding: 0 1.5rem;
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;

  .checkboxLabel {
    font-size: 12px;
  }
}

.avatar {
  width: 10rem;
  height: 10rem;
}

//sign
.key {
  width: 0.9rem;
  height: 1.2rem;
  background-image: url('../../assets/icon_key.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.labelIcon {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
}

.label {
  display: flex;
  align-items: center;
}

.mail {
  width: 1.1rem;
  height: 0.8rem;
  background-image: url('../../assets/icon_mail.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.check {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.fullName {
  display: flex;
  justify-content: flex-end;

  .firstName {
    label {
      display: block;
      margin-bottom: 1rem;
      font-weight: bold;

      i {
        margin-right: 0.8rem;
        display: inline-block;
      }
    }
  }

  .lastName {
    margin-right: 1.4rem;

    label {
      display: block;
      margin-bottom: 1rem;
      font-weight: bold;

      i {
        margin-right: 0.8rem;
        display: inline-block;
      }
    }
  }
}

.alignCentering {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
