.textField {
  width: 100%;
}

.multipleselect {
  min-width: 12rem;
  max-width: 20rem;
}
.select {
  select {
    min-width: 10rem;
    background: #fff;
    padding: 1.5rem;
    border-radius: 0.4rem;
    border: 0.1rem solid #ccc;
  }
  &:before {
    border: none !important;
  }
}

.avatar {
  cursor: pointer;
  background-size: cover;
  background-color: #ccc;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  p {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }
}

.textarea {
  background: #f7f7f7;
}

.requiredChoice {
  color: #f44336;
  margin-left: 1.4rem;
  font-size: 1.7rem;
  margin-top: 0.3rem;
  min-height: 1em;
  text-align: left;
  font-weight: 400;
  line-height: 2.6rem;
  letter-spacing: 0.03333em;
}

// global
input,
select {
  background: #f7f7f7;
}
textarea {
  min-height: 7rem;
}

:global {
  .react-autosuggest__container input {
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
  }
}
