.text {
  color: #222222;
  font-size: 1.6rem;
}

.title {
  color: #EA397D;
  font-size: 1.9rem;
  font-weight: bold;
}
