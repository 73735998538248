.container {
  border-bottom: 0.1rem solid #ddd;
  padding: 2.5rem 0 2.5rem 3.5rem;
  display: flex;
}

.header {
  max-width: 21rem;
  width: 100%;
  margin: 1.2rem 3rem 0 0;
  display: flex;
  justify-content: space-between;

  .text {
    color: #093791;
    font-size: 1.8rem;
  }

  .required {
    font-size: 1.4rem;
    color: #ea397d;
    border-radius: 1em;
    border: 0.1rem solid #ea397d;
    padding: 0.2rem 0.7rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .container {
    display: block;
    padding: 1rem 1.5rem 2.5rem 1.5rem;
  }
  .header {
    max-width: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8rem;
  }
}
