.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.buttonItem {
  width: 50%;
}

.formItems {
  width: 100%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}

.submit {
  margin-top: 2rem;
}

.error {
  color: #ff061a;
  font-size: 1.2rem;
}
