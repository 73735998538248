.container {
  display: flex;

  .image {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
  }

  .name {
    margin: auto 1rem;
  }
}
