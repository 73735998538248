.panel {
  background: center top / cover no-repeat
    url('../../assets/background_signin_image.jpg');
  background-color: #093791;
  min-height: 100vh;
  position: relative;

  .headerContainer {
    background-color: rgba(0, 0, 0, 0);
    width: 50rem;
    position: absolute;
    top: 2rem;
    right: 0rem;
    display: flex;
    justify-content: flex-end;
  }
  .requestDocument {
    margin-right: 1.6rem;
  }
  .ContactUs {
    margin-right: 2.4rem;
  }
  .logo {
    text-align: center;
    padding-top: 11rem;
  }

  .copyRight {
    text-align: center;
    color: #d7e2f4;
    padding: 5rem 0;
  }

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    padding: 5rem 0;
  }
}

@media screen and (max-width: 600px) {
  .inner {
    height: 100%;
    padding: 2rem 0 !important;
    margin: 0px;
    overflow: hidden;
  }
}
@media screen and (max-width: 375px) {
  .panel {
    .headerContainer {
      top: 3rem;
      width: 100%;
      justify-content: center;
    }
    .blank {
      width: 0.8rem;
    }
    .requestDocument {
      margin-right: 0rem;
    }
  }
}

@media screen and (min-width: 2599px) {
  .panel {
    background-size: cover;
  }
}
