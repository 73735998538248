.background {
  background-image: url('../../assets/sub_title_background.svg');
  height: 16.6rem;

  .title {
    font-weight: bold;
    font-size: 3.4rem;
    text-align: center;
    color: #ffffff;
    padding-top: 6.2rem;
  }
}
@media only screen and (max-width: 600px) {
  .title {
    font-size: 3rem !important;
  }
}
