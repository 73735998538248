.submit {
  display: block;
  margin-top: 4rem;
  margin-left: 1rem;

  input {
    display: none;
  }

  .close {
    margin-left: 1rem;
  }

  > label span {
    font-size: 1.4rem !important;
    font-weight: bold;
    width: 30rem;
    height: 5rem;
    border-radius: 0.5rem;
  }
}

.submits {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .submit {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
}
