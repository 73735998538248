.container {
  background-color: #093791;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  color: white;
}

.subText {
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 25rem;
  opacity: 0.7;
}
