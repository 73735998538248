.flex {
  display: flex;
}

.container {
  width: 100%;
  background-color: #F7F9FC;
}

.buttonContainer {
  text-align: center;
  margin-top: 4rem;
}

.containerItem {
  background-color: #FFFFFF;
  border: 0.1rem solid #D7E2F4;
  max-width: 88rem;
  margin: 3rem auto 0;
  padding: 5rem 8rem 7rem;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 70.8rem;
}

.body {
  color: #222222;
  font-size: 1.8rem;
  min-height: 7.6rem;
  width: 100%;
}

.fieldContainer {
  display: flex;
  margin: 3rem auto;
}

.title {
  color: #093791;
  font-size: 1.8rem;
  font-weight: bold;
  width: 17rem;
  margin-top: 1.2rem;
}

.border {
  background-color: #DDDDDD;
  max-width: 88rem;
  height: 0.1rem;
}

.errorContainer {
  margin-left: 17rem;
}

.fullWidth {
  width: 100%;
}

.subTitle {
  color: #222222;
  font-size: 1.8rem;
  min-height: 7.6rem;
  width: 15rem;
}
