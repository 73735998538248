.rootContainer {
  width: 100%;
}

.container {
  width: auto;
  padding: 1.8rem;
  display: flex;
  justify-content: space-between;
}

.engineerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
}

.blueText {
  color: #093791;
  font-size: 1.6rem;
}

.buttons {
  display: flex;
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.engineerItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin-left: 3rem;
}
