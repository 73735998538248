.main {
  padding: 3rem;
  background-color: #ffffff;
}

.row {
  display: flex;
  align-items: center;
}

.itemPadding {
  padding: 3rem;
}

.rowOverview {
  display: flex;
}

.rowTitle {
  width: 18rem;
  min-width: 18rem;
}

.rowInputFullWidth {
  width: 100%;
}

.rowRequire {
  width: 6rem;
  min-width: 6rem;
}

.required {
  border-radius: 1rem;
  border: 0.1rem solid #ea397d;
  color: #ea397d;
  margin-right: 1rem;
  font-size: 1rem;
  padding: 0.2rem 0.6rem;
  position: relative;
  background: #ffffff;
}

.CategoryText {
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #093791;
}

.submit {
  display: block;
  margin-top: 2.5rem;
}

.submits {
  display: flex;
  justify-content: center;
}

.mainCategory {
  //display: flex;
  margin-bottom: 5rem;
}

.mainContent {
  border: 0.1rem solid #d7e2f4;
}

.hyphen {
  margin: 1rem
}

.description {
  width: 70%;
}

.descriptionContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


@media screen and (max-width: 600px) {
  .row {
    display: flex;
    align-items: baseline;
    flex-direction: column;
  }
  .rowOverview {
    display: flex;
    flex-direction: column;
  }
  .itemPadding {
    padding: 3rem 0;
  }
  .rowRequire {
    width: 6rem;
    min-width: 6rem;
    margin-bottom: 1rem;
  }
  .main {
    padding: 3rem 1.6rem;
    background-color: #ffffff;
  }
  .description {
    width: 95%;
  }
}
