.background {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;

  .listItem {
    padding: 1.6rem;
    border-bottom: 0.1rem solid #D7E2F4;
  }

  .text {
    display: flex;

    .questionMark {
      margin-right: 1rem;
    }
  }
}

@media only screen and (min-width: 769px) {
  .background {
    position: fixed;
    max-width: 28rem;
  }
}
