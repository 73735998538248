.background {
  height: 100vh;
  background: no-repeat url("../../assets/background_gradation.svg");
  background-size: cover;

  .logo {
    padding-top: 7rem;
    text-align: center;
  }

  .main {
    background-color: #FFF;
    max-width: 70rem;
    border-radius: 0.8rem;
    margin: 4rem auto;

    .inner {
      max-width: 48rem;
      margin: 0 auto;
    }
  }
}

.title {
  color: #00A8F0;
  font-size: 2.8rem;
  text-align: center;
  padding-top: 5rem;
}

.fieldTitle {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;

  .fieldTitleText {
    color: #999;
    font-size: 1.4rem;
    margin-left: 0.5rem;
  }
}

.nameField {
  display: flex;
  justify-content: space-between;

  input {
    width: 20.5rem;
  }
}

.submitButton {
  margin: 4rem auto;
  padding-bottom: 5rem;
  max-width: 30rem;
  height: 5rem;
}

.copyright {
  text-align: center;
  padding-bottom: 5rem;
}

.error {
  color: #f44336;
  font-size: 0.75rem;
  margin-top: 0.8rem;
  margin-left: 1.2rem;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.textFieldContainer {
  width: 80%;
}

@media screen and (max-width: 600px) {
  .submitButton {
    margin-top: 1.5rem;
    padding-bottom: 0;
    max-width: 30rem;
    height: 5rem;
  }
}
