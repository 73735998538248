.dialog {
  padding: 2rem 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.8rem;
  line-height: 4.1rem;
  color: #00a8f0;
  margin-top: 2rem;
  margin-bottom: 3.7rem;
}

.subText {
  color: #222222;
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-bottom: 1rem;
}

.link {
  color: #093791;
  font-weight: bold;
  font-size: 1.2rem;
}

.taskBar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.4rem;
  background: #093791;
  border-radius: 0.4rem;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.7rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1.7rem;
}

.submitDateTime {
  width: 100%;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #999999;
  margin-bottom: 1rem;
}

.taskName {
  width: 100%;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: #093791;
  margin-bottom: 1.5rem;
}

.userInfoContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
}

.username {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #222222;
  margin-left: 1rem;
}

.ratingContainer {
  padding: 1.6rem 2rem;
  width: 48rem;
  background: #eff2f7;
  border-radius: 0.6rem;
  margin-bottom: 2rem;
}

.divider {
  border-bottom: 0.1rem solid #dddddd;
  width: 100%;
  margin-bottom: 3rem;
}

.ratingTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: #093791;
}

.ratingDetail {
  font-style: normal;
  font-size: 1.2rem;
  line-height: 3rem;
  color: #093791;
}

.textAreaHeader {
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #093791;
  width: 100%;
  margin-top: 1.4rem;
}

.textFormContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 600px) {
  .dialog {
    padding: 2rem 2rem;
  }
  .mainText {
    font-size: 2.2rem;
  }
  .flex {
    display: flex;
    justify-content: center;
  }
  .ratingContainer {
    width: 25rem;
    margin-bottom: 1rem;
  }
  .mainText {
    margin: 1rem 0;
  }
  .taskName {
    margin-bottom: 0.5rem;
  }
  .userInfoContainer {
    margin-bottom: 0.5rem;
  }
  .textAreaHeader {
    margin-top: 0rem;
  }
}
