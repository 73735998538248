.rootContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upperContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lowerContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.name {
  min-width: 8rem;
  color: #999999;
  font-size: 1.2rem;
}

.blankWidth {
  padding: 0.5rem;
}

.itemSet {
  display: flex;
  align-items: center;
  width: 48%;
  z-index: 1;
}

.buttons {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .upperContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .itemSet {
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 1;
  }
  .lowerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .buttons {
    display: flex;
    align-items: center;
    margin-top: 1.6rem;
  }
  .rootContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
