.container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.flex {
  display: flex;
}
.bold {
  font-size: 2.2rem;
  font-weight: bold;
}
.nonDeadline {
  font-size: 1.4rem;
  color: #999;
}

.text {
  font-size: 1.4rem;
  margin-top: 0.8rem;
}
@media screen and (max-width: 600px) {
  .container {
    justify-content: center;
    width: 5rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0.2rem;
    margin-bottom: 0rem;
  }
  .flex {
    display: flex;
  }
}
