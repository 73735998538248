.imgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projectImg {
  width: 70%;
  border-radius: 1rem;
  border-width: 0.1rem;
  border-style: solid;
  border-color: #ffd6ff;
}

.title {
  font-weight: bold;
  color: #093791;
  font-size: 1.8rem;
}

.innerContainer {
  padding: 1.6rem;
}

.text {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.texts {
  display: flex;
  align-items: center;
}

.caption {
  font-size: 1.2rem;
  font-weight: bold;
  color: #555555;
}

@media screen and (max-width: 600px) {
  .projectImg {
    width: 100%;
    border-radius: 1rem;
    border-width: 0.1rem;
    border-style: solid;
    border-color: #ffd6ff;
  }
}
