.required {
  border-radius: 1rem;
  border: 0.1rem solid #EA397D;
  color: #EA397D;
  margin-right: 3rem;
  font-size: 1rem;
  font-weight: normal;
  padding: 0.2rem 0.6rem;
  position: relative;
  background: #FFFFFF;
  line-height: normal;
}
